import useFetch from './useFetch';
import useAuth from './useAuth';

export default function useSession(){
    
    const { ZONE, USER, listStations } = useFetch();
    const { lZones } = ZONE();
    const { lAdmin, lDN, lRRO, lRO, lRM, lPRL } = USER();
    const { token } = useAuth();
    
    // ..session vars
    const storedSTATIONS = window.sessionStorage.getItem('stations')

    const HandleSessionStorage = () => {
        
        // ..if logged
        if (token) {
            lZones()
            lStations()
            lAdmin().then(()=> lDN())
            lRRO().then(()=> lRO().then(()=> lRM().then(()=> lPRL())))
        }
    }

    const lStations = () => {
        const objSTATIONS = JSON.parse(storedSTATIONS) || []
        return new Promise ((resolve, reject)=>{
            if(!storedSTATIONS) listStations().then(({ status, json }) => { if (status === 200) json.then(res => {window.sessionStorage.setItem('stations', JSON.stringify(res.data || []));resolve(res.data) });})
            resolve(objSTATIONS);
        })
    }

    return{
        HandleSessionStorage,
        lZones,
        lAdmin
    }
}