import { StyledArticleBordered, StyledHeadContainer, StyledTitleContainer } from "./style";

export function ArticleBordered ({ children, ...rest }) {
  return <StyledArticleBordered {...rest}>{children}</StyledArticleBordered>;
};

ArticleBordered.Head = function HeadArticle({children, title, ...rest}){
  return (
    <StyledHeadContainer  className={"px-3 pt-1"} {...rest}>
      <StyledTitleContainer>{title}</StyledTitleContainer>
      {children}
    </StyledHeadContainer>
  );
}

export default ArticleBordered