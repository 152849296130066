import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  display: grid;
  border-bottom: 1px solid #a8a8a7;
  // border-top-left-radius: 1rem;
  // border-top-right-radius: 1rem;
  grid-template-columns: 14.37% minmax(180px,42.54%) minmax(350px,20.4%) minmax(170px,11.99%) minmax(140px,10.67%);
  text-transform: uppercase;

  *{
    display: inherit;
    align-content:end;
  }
  
  > *:not(:first-child) {
    padding-bottom: .2rem;
    padding-top: .5rem;
    border-left: 1px solid #a8a8a7;
  }

  @media (max-width: 991px){
    grid-template-columns: minmax(30px, 140px) 1fr;
    // grid-template-rows: repeat(2, fit-content);
    // grid-template-columns: repeat(5, 1fr);
    // grid-template-areas:
    //   "lg lg us us us"
    //   "su su cl cl rl";
  }

  @media (max-width: 500px){
    // grid-template-rows: repeat(3, fit-content);
    // grid-template-columns: repeat(4, 1fr);
    // grid-template-areas:
    //   "lg lg us us"
    //   "su su su su"
    //   "cl cl rl rl";
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: .7rem;
  // @media (max-width: 959px){grid-area: lg;border-bottom: 1px solid #a8a8a7;}

  & > img {
    align-self: flex-end;
    justify-self: center;
    width : 100%;
    max-width: 11.8rem;
    padding-top: 1.5rem;
  }
`;

export const HamburgerMenu = styled.a`
  position: absolute;
  right: .4rem;
  top: .3rem;
  height: 2.3rem;
  display: none;
  @media (max-width: 991px){
    display: block;
  }
`

export const BurgerLine = styled.span`
  width: 20px;
  display: block;
  height: 2px;
  background-color white;
  margin .3rem;
  margin-top: -.0001rem;
`

/**
 * HeaderUser
 * --------------------------------------------
 */
export const UserContainer = styled.div`
  // @media (max-width: 959px){grid-area: us;border-bottom: 1px solid #a8a8a7;}

  & > * {
    padding-left: 15px;
  }
  font-size: 0.7rem;
  font-weight: 600;
`;
export const SpanWelcome = styled.span`
  display: block;
  color: #797979;
`;

export const SpanUser = styled.span`
  display: block;
  color: black;
  border-bottom: 1px solid #a8a8a7;
  position: relative;
  width: fit-content;
  min-width:130px;
  max-width: 95%;
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    right: 0;
    height: 4px;
    width: 5.5px;
    background-color: white;
  }
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const LogoutLink = styled(Link)`
  display: block;
  margin-top: 0.2rem;
  font-size: 0.57rem;
  text-decoration: none;
  color: #515298;
`;

/**
 * HeaderSummary
 * --------------------------------------------
 */
export const SummaryContainer = styled.div`
@media (max-width: 959px){grid-area: su;}
@media (max-width: 500px){border-bottom: 1px solid #a8a8a7;}
display: none;
// padding-left: 10%;
`

export const TltSummary = styled.h3`
  font-size: 1rem;
  font-weight: 800;
  margin:0;
`

export const SpanValue = styled.span`
  display: flex;
  align-items: center;
  overflow:hidden;
  font-family: Agenda-bold;
`

export const Value = styled.h3`
  font-size: 2.2rem;
  font-weight: 700;
  margin:0;
`

export const Label = styled.span`
  font-size: .9rem;
  font-weight: 700;
  white-space: nowrap;
  padding-left: .625rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;

  &:before{
    content: "";
    background-color: #77508D;
    mask-image:url('/img/icons/arrow-right.svg');
    mask-repeat: no-repeat;
    width: .62rem;
    height: .62rem;
    margin-right: .1rem;
    display: inline-block;
  }
`
/**
 * HeaderClock
 * --------------------------------------------
 */
export const ClockContainer = styled.span`
  @media (max-width: 959px){grid-area: cl;}
  display: none;
  align-items: end;
  justify-content: center;
  width: 100%;
`

export const SpanClock = styled.span`
  font-weight: 300;
  font-size: 2.4rem;
  color: #909090;
  display: flex;
  align-items: center;

  &:before{
    content: "";
    background-color: #AFABAC;
    mask-image:url('/img/icons/clock.svg');
    width: .95rem;
    height: .95rem;
    margin-right: .6vmax;
  }
`

/**
 * HeaderRole
 * --------------------------------------------
 */
export const RoleContainer = styled.div`
  @media (max-width: 959px){grid-area: rl;}
  display: none;
  padding-left: .7rem; //TODO: puede ser general?
`
export const SpanRole = styled.span`
  font-size: 2.3rem;
  font-weight: 800;
  color: #909090;
  margin: 0;
`
export const SpanZone = styled.span`
  display: block;
  font-weight: 700;
  color: #909090;
  margin-top: -12px;
`