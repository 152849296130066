import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware'
import { API, HEADERS } from '../constants';

export const useAuthStore = create(
persist(
    set => ({
        user: {},
        token: undefined,
        setUser: val => set(() => ({ user: val})),
        setToken: val => set(() => ({ token: val})),
    }),
    {
        name: 'auth',
        storage: createJSONStorage(()=> localStorage),
    }
))
;

const useAuth = () => {
    const {user, token, setToken, setUser } = useAuthStore()
    function SignInAccount(email, password){
        fetch(`${API}/api/login`,{
            method: 'POST',
            headers:HEADERS,
            body: JSON.stringify({
                "name":"false",
                "email":email,
                "password": password
            })
        })
        
        .then((response)=>{
            let body = response.json()
            body.then(res=>{
                if(response.status === 200) {setToken(res.token);setUser({...res.user,'zone': {...res.zone}});}
                else{alert("Usuario incorrecto")}
            })
        })
    }

    function logout(e){
        e.preventDefault();
        sessionStorage.clear()
        localStorage.clear()
        setToken(undefined)
        window.location.reload()
    }

    return {user, token, SignInAccount, logout}
}

export default useAuth;