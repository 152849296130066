const { default: styled } = require("styled-components");

// ..Provisional
export const StyledMainTemplate = styled.div`
  display: flex;
  // min-height: 100vh;
  align-items: center;
  flex-direction: column;
  // @media (min-width:992px){
  //   padding: .5rem;
  // }
`

export const Body = styled.div`
  width: 100%;
  // border-left: 1px solid #a8a8a7;
  // border-right: 1px solid #a8a8a7;
  display: grid;
  grid-template-columns: 0 1fr;
  padding-bottom: 7vh;
  padding-top: 3vh;
  @media (min-width:992px){
    padding-top: 8vh;
    grid-template-columns: minmax(13.6rem, 1fr) 6fr;
    column-gap: 2rem;
  }
  `
  
  export const ChildrenContainer = styled.div`
  // ...
  overflow-x: hidden;
`
