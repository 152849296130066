import Select from "../../../components/form/select";
import { StyledBorderArticle, StyledContainerValue, StyledFirstPart, StyledRowInput, StyledSection2, StyledArticle3, StyledArticle4, StyledSection5, SpanSignature, StyledSection6, StyledSection7 } from "../styles";
import { useTools } from '../../../hooks/useTools';
import { UploadImage } from '../utils/upload-photo'
import { OkCancelGroup } from "../utils/okcancel-group";

const RowInput = ({ children }) => {
  return (
    <StyledRowInput
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </StyledRowInput>
  );
};

const ContainerValue = ({ children }) => {
  return (
    <StyledContainerValue
      style={{
        display: 'inline-block',
        paddingTop: '.3em',
        paddingLeft: '1.2em',
        paddingRight: '1.2em',
        width: 'fit-content',
        textAlign: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '.6rem',
        marginBottom: '.5rem',
      }}
    >
      {children}
    </StyledContainerValue>
  );
};

const LastRowInfo = ({ children }) => {
  return <div style={{ height: '1.6rem', display: 'block', borderTop: '1px solid white', paddingTop: '.2rem', paddingLeft: '1rem', width: '100%' }}>{children}</div>
}

export const FIRST_PART = () => {
  const { formatEUR } = useTools()
  return (
    <StyledFirstPart
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: '.51rem 0',
        marginTop: '.2rem',
        justifyContent: 'space-around',
        gap: '1rem',
      }}
    >
      <article
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: '.8',
          minWidth: '8rem',
          padding: '.1rem'
        }}
      >
        <RowInput>
          <label htmlFor="" >
            us
          </label>
          <Select
            options={['', 'Alcalá de Henares']}
            onValue={val => {
            }}
          />
        </RowInput>

        <RowInput>
          <label htmlFor="" >
            tipo
          </label>
          <Select
            options={['', '2']}
            onValue={val => {
            }}
          />
        </RowInput>

        <RowInput>
          <label htmlFor="">
            urgencia
          </label>
          <Select
            options={['', 'Leve']}
            onValue={val => {
            }}
          />
          <label htmlFor="" style={{ paddingLeft: '1rem' }}>
            horario
          </label>
          <Select
            options={['', 'NOCTURNO']}
            onValue={val => {
            }}
          />
        </RowInput>

        <RowInput>
          <label htmlFor="">
            garantía
          </label>
          <Select
            options={['', 'NO']}
            onValue={val => {
            }}
          />
          <label htmlFor="" style={{ paddingLeft: '1rem' }}>
            garantía
          </label>
          <Select
            options={['', 'NO']}
            onValue={val => {
            }}
          />
        </RowInput>

        <RowInput>
          <label htmlFor="" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            tipo de siniestro
          </label>
          <Select
            options={['', 'OPTION']}
            style={{ width: '50%' }}
            onValue={val => {
            }}
          />
        </RowInput>

        <RowInput>
          <label htmlFor="" style={{ width: '50%' }}>
            imputación
          </label>
          <Select
            options={['', 'OPTION']}
            style={{ width: '50%', fontWeight: '400' }}
            onValue={val => {
            }}
          />
        </RowInput>

        <RowInput>
          <label htmlFor="" style={{ width: '50%' }}>
            proveedor
          </label>
          <Select
            options={['', 'OPTION']}
            style={{ width: '50%' }}
            onValue={val => {
            }}
          />
        </RowInput>
      </article>

      <StyledBorderArticle
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '.8',
          alignItems: 'start',
          paddingLeft: '1rem',
          minWidth: '16rem',
          maxWidth: '30rem',

        }}
      >
        <h1 style={{ fontSize: '2em', width: 'calc(100% - 1rem)', padding: '.1rem .5rem', borderRadius: '.3rem', marginTop: '.5rem', background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <strong style={{ fontSize: '.47em', verticalAlign: 'middle' }}>
            PEDIDO Nº
          </strong>{' '}
          <span>
            003-2204281345
          </span>
        </h1>
        <div>
          <ContainerValue>
            <strong>COSTE ESTIMADO RM</strong>
            <div>
              <strong style={{ fontWeight: '600', fontSize: '2rem', marginBottom: 0, paddingBottom: 0 }}>
                {formatEUR(9208.8)}
              </strong>
            </div>
          </ContainerValue>
          {/* <span>APROBAR</span> TODO: MASK-IMAGE
      <span>DENEGAR</span> */}
      <span style={{position: 'absolute'}}>
      <OkCancelGroup />
      </span>
        </div>
        <div style={{ width: 'calc(100% + 1rem)', marginLeft: '-1rem' }}>
          <LastRowInfo>
            <strong>NOMBRE RO:</strong>
            <span> VB BEATRIZ LLORENTE</span>
          </LastRowInfo>
          <LastRowInfo>
            <strong>CARGO:</strong>
            <span> RESPONSABLE DE OPERACIONES</span>
          </LastRowInfo>
          <LastRowInfo>
            <strong>06 / 02 / 2023</strong>
          </LastRowInfo>
          <LastRowInfo>
            <strong>NOMBRE RM:</strong>
            <span> JUAN ROMERO GARCÍA</span>
          </LastRowInfo>
        </div>
      </StyledBorderArticle>
    </StyledFirstPart>
  );
};


export function SECOND_PART() {
  const { formatEUR } = useTools()
  const TtlSection = ({ center, children }) => {
    return (<h3 style={{ textAlign: (center ? 'center' : 'inherit'), fontSize: '1rem', fontWeight: '600', marginTop: '.5rem', marginBottom: '.2rem' }}>
      {children}
    </h3>)
  }

  const DivText = ({ children, ...rest }) => {
    return (<div style={{ fontSize: '1em' }} {...rest}>{children}</div>)
  }

  return (
    <>
      {/* SECCIÓN 2 : DETALLE DE LA SOLICITUD */}
      <Section2>
        <TtlSection center>DETALLE DE LA SOLICITUD</TtlSection>
        <div style={{ position: 'relative' }}>
          <label className="tltFloat" htmlFor="RO_txt">RO</label>
          <textarea
            name=""
            id="RO_txt"
            style={{ display: 'block', width: '100%' }}
            onChange={()=>{}}
          />
          <UploadImage vector={"ARR1"} />
        </div>
        <div style={{ position: 'relative' }}>
          <label className="tltFloat" htmlFor="RO_txt">RM</label>
          <textarea
            name=""
            id="RO_txt"
            style={{ display: 'block', width: '100%' }}
            onChange={()=>{}}
          />

          <div className="form-check" style={{ position: 'absolute', right: 0, bottom: -2, display: 'flex', alignItems: 'center', backgroundColor: '#B2B2B2', padding: '.24rem .5rem', borderRadius: '.3rem 0 .3rem 0', pointerEvents: 'none' }}>
            <label style={{ paddingRight: '.4rem', fontWeight: '500', color: "#575756" }} htmlFor="checkPRL">ACTIVAR PRL</label>
            <input type="checkbox" value="" id="checkPRL" style={{ pointerEvents: 'fill' }} />
          </div>
        </div>
      </Section2>

      {/* SEGUNDA PIEZA */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {/* SECCIÓN 3 : IMPORTE A PROVISIONAR... */}
        <Article3>
          <TtlSection center>IMPORTE A PROVISIONAR POR EL RESPONSABLE DE MANTENIMIENTO DE ZONA</TtlSection>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 650, margin: 'auto', flexWrap: 'wrap' }}>
            <span style={{ width:'20%',minWidth: '7rem', display:'block', margin: '1% 2%'}}>
              <label htmlFor="netValue" style={{ textAlign: 'right' }}>IMPORTE NETO</label>
              <input id="netValue" type="text" style={{ textAlign: 'right', fontWeight: '600', paddingRight: '.3rem', display: 'block', width: '100%', fontSize: '1.2em' }} onChange={()=>{}} value={formatEUR(9208.04)}/>
            </span>
            <span style={{ width: '20%', minWidth: '7rem', display:'block', margin: '1% 2%'}}>
              <label htmlFor="netValue">IMPUESTOS</label>
              <input id="netValue" type="text" style={{ textAlign: 'right', fontWeight: '600', paddingRight: '.3rem', display: 'block', width: '100%', fontSize: '1.2em' }} onChange={()=>{}} value={formatEUR(22.4)}/>
            </span>
            <span style={{ width: '20%', minWidth: '7rem', display:'block', margin: '1% 2%'}}>
              <label htmlFor="netValue">TOTAL</label>
              <input id="netValue" type="text" style={{ textAlign: 'right', fontWeight: '600', paddingRight: '.3rem', display: 'block', width: '100%', fontSize: '1.2em' }} onChange={()=>{}} value={formatEUR(230.8)}/>
            </span>
            <span style={{ width: '23%', minWidth: '7rem', display:'block', margin: '1% 2%'}}>
              <label htmlFor="netValue" style={{ whiteSpace:'nowrap' }}>FECHA DE EJECUCIÓN</label>
              <input id="netValue" type="date" style={{ textAlign: 'right', fontWeight: '600', paddingRight: '.3rem', display: 'block', width: '100%', fontSize: '1.2em' }} />
            </span>
          </div>
        </Article3>

        {/* SECCIÓN 4 : AUTORIZACIÓN DIRECTOR NACIONAL... */}
        <Article4>
          <TtlSection>AUTORIZACIÓN DIRECTOR NACIONAL</TtlSection>
          <DivText>
            <strong>NOMBRE PRL: </strong> Nombre Apellido Apellido
          </DivText>
          <DivText>
            <strong>CARGO: </strong> Nombre Apellido Apellido
          </DivText>
          <DivText>
            <strong>FECHA: 28/04/2022</strong>
          </DivText>
          <OkCancelGroup />
        </Article4>

        {/* SECCIÓN 5 : PRL */}
        <Section5>
          <TtlSection>PRL</TtlSection>
          <DivText>
            <strong>NOMBRE PRL: </strong> Nombre Apellido Apellido
          </DivText>
          <DivText>
            <strong>CARGO: </strong> Nombre Apellido Apellido
          </DivText>
          <DivText>
            <strong>FECHA: 28/04/2022</strong>
          </DivText>
          <SpanSignature />
          <OkCancelGroup />
        </Section5>

        {/* SPLIT */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '1rem' }}>
          {/* SECCIÓN 6 (1/2) : CONTROL DE CALIDAD */}
          <Section6 style={{ flex: 1 }}>
            <TtlSection center>CONTROL DE CALIDAD</TtlSection>
            <DivText>
              <strong>NOMBRE RO:</strong>
              <span>VB BEATRIZ LLORENTE</span>
            </DivText>
            <DivText>
              <strong>CARGO:</strong>
              <span>RESPONSABLE DE OPERACIONES</span>
            </DivText>
            <DivText>
              <strong>FECHA: 28/04/2022</strong>
            </DivText>
            <div style={{position: 'absolute', bottom: '1.5rem', left: '3rem'}}>
              <UploadImage vector={"ARR2"}/>
            </div>
            <OkCancelGroup />
          </Section6>
          {/* SECCIÓN 7 (1/2) : ...CASO DE DIFERENCIA... */}
          <Section7>
            <TtlSection center>
              COMPLETAR EN CASO DE DIFERENCIA EN IMPORTE DE FACTURA
            </TtlSection>
            <DivText style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <strong>FECHA FACTURA</strong>
              <input type="text" />
            </DivText>
            <DivText style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <strong>IMPORTE NETO EN FACTURA</strong>
              <input type="text" />
            </DivText>
            <DivText style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <strong>TOTAL</strong>
              <input type="text" />
            </DivText>
            <hr />
            <DivText>
              <strong>NOMBRE RM CONFORMIDAD:</strong>
              <span>JUAN ROMERO GARCÍA</span>
            </DivText>
            <DivText>
              <strong>FECHA:</strong>
              <span> 28/04/2022</span>
            </DivText>
          <OkCancelGroup />
          </Section7>
        </div>
      </div>
    </>
  );
};

// TODO: Agrupar acticles
const Section2 = ({ children }) => {
  return <StyledSection2>{children}</StyledSection2>
}

const Article3 = ({ children }) => {
  return <StyledArticle3 style={{ flex: 1, width: '100%', padding: '.2rem .8rem' }}>{children}</StyledArticle3>
}

const Article4 = ({ children }) => {
  return <StyledArticle4 style={{ flex: 1, position: 'relative', width: '100%', paddingTop: '.4rem', paddingLeft: '1.9rem', paddingBottom: '1rem', margin: '.5rem 0' }}>{children}</StyledArticle4>
}

const Section5 = ({ children }) => {
  return <StyledSection5 style={{ flex: 1, width: '100%', paddingTop: '.2rem', paddingLeft: '1.9rem', paddingBottom: '.5rem', marginBottom: '.5rem' }}>{children}</StyledSection5>
}

const Section6 = ({ children }) => {
  return <StyledSection6 style={{ flex: 1, position: 'relative' }} >{children}</StyledSection6>
}

const Section7 = ({ children }) => {
  return <StyledSection7 style={{ flex: 1, backgroundColor: '#E5E7EB', position: 'relative', paddingRight: '1rem' }} >{children}</StyledSection7>
}