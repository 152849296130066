import { useRef } from "react";
import { useStorePhotos } from "../store";
import { StyledUploadImage } from "../styles";

export const UploadImage = ({vector}) => {

const refInputFile = useRef(null)
const {ARR_IMG1, setARR_IMG1, ARR_IMG2, setARR_IMG2} = useStorePhotos()

const handleChange = (vector) => {
  var file = refInputFile.current.files[0]
  let reader = new FileReader();
  console.log(vector)
  reader.addEventListener('loadend', (event)=>{
    const ARR = vector === 'ARR1' ? ARR_IMG1 : ARR_IMG2;
    const setARR = vector === 'ARR1' ? setARR_IMG1 : setARR_IMG2
    let value = []
    if (event.target.result) {
      value.push(...ARR, event.target.result)
      if(vector === 'ARR1'){
        setARR(value)
      }
      if(vector === 'ARR2'){
        setARR(value)
      }
    }
  })
  reader.readAsDataURL(file)
  refInputFile.current.value = "";
}

  return (<><StyledUploadImage onClick={(e) => { e.preventDefault();refInputFile.current.click() }} />
    <input ref={refInputFile} type="file" name="inputFile" id="inputFile" hidden onChange={(e)=>{e.preventDefault();handleChange(vector)}} accept=".png,.jpg,.svg,.webp" /></>)
}