import { StyledMenuBar, StyledMenuItem, StyledMenuList, StyledTitle } from "./menu-bar.styles"

export default function MenuBar({children, ...rest}){
    return <StyledMenuBar {...rest}>{children}</StyledMenuBar>
}

MenuBar.Title = function MenuBarTitle({children,...rest}){
    return <StyledTitle {...rest}>{children}</StyledTitle>
}

MenuBar.List = function MenuBarList({children, ...rest}){
    return <StyledMenuList {...rest}>{children}</StyledMenuList>
}

MenuBar.Item = function MenuBarItem({children, ...rest}){
    return <StyledMenuItem {...rest}>{children}</StyledMenuItem>
}