import styled from "styled-components";

export const StyledHeading = styled.div`
  background-color: #77508D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const SpanTitle = styled.span`
  color: white;
  padding-right: 2rem;
  font-weight: 600;
  font-size: 1.65rem;
  text-transform: uppercase;
  @media (max-width: 991px){
    font-size: calc(1.65rem + 1vmax);
  }
`

export const SpanNavigation = styled.span`
  display: flex;
  color: white;
  font-size:1.65rem;
  font-weight: 600;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledSpan = styled.span`
  font-weight: 600;
  font-size: .86rem;
`

export const StyledInputContainer = styled.span`
  background-color: white;
  padding: .05rem .8rem;
  font-size: .85rem;
  border-radius: .5rem;
  margin-left: .5rem;
  margin-right: 1rem;
  
  & select, & input{
    background-color: white;
    font-weight: 600;
    appearance: none;
    border: none;
    outline: none;
    width: 100%;
  }
`

export const StyledEndHead = styled.div`
  text-align: right;
`