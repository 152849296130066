import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  display: grid;
  position: fixed; // Nuevo*
  bottom: 0; // Nuevo*
  border-top: 1px solid #a8a8a7; // Nuevo*
  background-color: white; // Nuevo*
  z-index:9; // Nuevo*
  // border-bottom-left-radius: 1rem; // Elimina borde*
  // border-bottom-right-radius: 1rem; // Elimina borde*
  text-transform: uppercase;
  color : #797979;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  padding: .3rem 2%;
  font-size: .68rem;
`;

export const SpanCopy = styled.span``
export const SpanVersion = styled.span``