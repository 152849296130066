
import { useEffect, useRef, useState } from 'react';
import { Icon, SpanBadge, SpanBorderBadge } from '../../../components'
import { useTools } from '../../../hooks/useTools';
import useFetch from '../../../hooks/useFetch'
import { useLocation, useNavigate } from 'react-router-dom';

function Section1Page22Modal({ userType,userSelected, onChange }) {
  const refCloseModal = useRef();
  const refSelectZone = useRef();
  const refSelectRO = useRef();
  const refSelectTempStation = useRef();
  const {formatEUR} = useTools();

  const dic = {
    admin: 'Administrador',
    dn: 'Director Nacional',
    rro: 'RRO',
    ro: 'RO',
    rm: 'RM',
    prl: 'PRL'
  };
  // const [ZONES, setZONES] = useState([{name: "ZONA 01", id: "ZONE1", value:8400},{name: "ZONA 02", id: "ZONE2", value:3600},{name: "ZONA 03", id: "ZONE3", value:0}])
  const [ZONES, setZONES] = useState([])
  const [selectedZONES, setSelectedZONES] = useState([])
  // const [listRO, setListRO] = useState([{name: "Juan López Méndez"}, {name:"Ernesto García"}, {name: "Juan Luis Perez Rodriguez"}])
  const [listRO, setListRO] = useState([])
  const [tempStationsList, setListStations] = useState([])
  const [selectedTempStationsList, selectTempStation] = useState([])
  const [selectedRO, setSelectedRO] = useState([])
  const location = useLocation()
  const { USER } = useFetch()
  const [useData, setData] = useState({})
  const [useFields, setFields] = useState({})
  const navigate = useNavigate()

  const modalTrigger = document.querySelectorAll('[data-modal-type]');
  const createTrigger = modalTrigger.item(1)
  const editTrigger = modalTrigger.item(0)

  useEffect(()=>{
    // LIST ZONES..
    const storedZONES = window.sessionStorage.getItem('zones');
    const objZONES = JSON.parse(storedZONES) || [];
    setZONES([...objZONES])

    // LIST RO..
    const storedRO = window.sessionStorage.getItem('ro')
    const objRO = JSON.parse(storedRO) || [];
    setListRO([...objRO])
    
    // LIST STATIONS..
    const storedSTATIONS = window.sessionStorage.getItem('stations')
    const objSTATIONS = JSON.parse(storedSTATIONS) || [];
    setListStations([...objSTATIONS])
  },[location])

  useEffect(() => {
    setFields({})
    if (userSelected){
      setData({ title: 'Editar', btnSend: 'Actualizar', user: userSelected })
      USER(userSelected).awaitInfo()
      .then(res=> {setFields(res?.data || {})
        setSelectedZONES(res.zones)
        setSelectedRO(res.ro)
        selectTempStation(res.stations)
      })
    }else{
      setData({ title: 'Añadir', btnSend: 'Enviar', user: undefined })
      setSelectedZONES([])
    }
  }, [location,userSelected])

  return (
    <div
      className="modal fade"
      id="createResourceModal"
      tabIndex="-1"
      aria-labelledby="createResourceModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-1">
            <h5 className="modal-title text-uppercase" id="createResourceLabel">
              <span className='fw-semibold fs-6 text-secondary' >{useData?.title || 'Añadir'}</span> <span className='fw-semibold'>{/*useData?.user ||*/ (dic[userType]||'')}</span>
            </h5>
            <button
              type="button"
              className="btn-close mb-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={refCloseModal}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <RowModal>
                <LabelRow htmlFor="name">Nombre</LabelRow>
                <InputRow id="name" name="name" required defaultValue={useFields?.name || ''} />
              </RowModal>
              <RowModal>
                <LabelRow htmlFor="email">Email</LabelRow>
                <InputRow id="email" type="email" name="email" required defaultValue={useFields?.email || ''}/>
              </RowModal>
              <RowModal>
                <LabelRow htmlFor="pass">Contraseña</LabelRow>
                <InputRow
                  aria-describedby="passHelp"
                  id="pass"
                  name="pass"
                  type="password"
                  required={!userSelected}
                >
                  {
                    userSelected && (
                      <div id="passHelp" className="form-text mt-0">
                        <small>Dejar en blanco si no se quiere modificar la contraseña.</small>
                      </div>
                    )
                  }
                </InputRow>
              </RowModal>
              {userType === 'rro' || userType === 'ro' ? (
                <>
                  <hr />
                  <div className="mb-3 row">
                    <label htmlFor="#" className="col-sm-4 py-0 col-form-label text-secondary fw-semibold text-uppercase">
                      ZONA ASOCIADA
                    </label>
                    <div className="col-sm-8 d-flex align-items-end">
                      <select className="form-select py-0 text-uppercase" defaultValue={'default'} ref={refSelectZone} onChange={()=>{
                        let position = parseInt(refSelectZone.current.value)
                        let arr = [...selectedZONES, ZONES[position]]
                        if(userType === 'ro' && selectedZONES.length >0){
                          alert("Ya tiene una zona asociada")
                          refSelectZone.current.value = "default"
                        }else{
                          setSelectedZONES(arr)
                          let new_arr = ZONES
                          new_arr.splice(position, 1)
                          setZONES(new_arr)
                          refSelectZone.current.value = "default"
                        }
                      }}>
                        <option disabled value="default">
                          Elegir zona
                        </option>
                        {
                          ZONES.map((v,i)=>(
                            <option key={v.id} value={i}>{v.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="mb-3 d-flex flex-wrap">
                    {
                      selectedZONES.map((v,i)=>(
                      <SpanBorderBadge style={{minWidth:'50%'}} key={"SZONES-"+i}>
                        <span>{v.name} <small className={"text-secondary fw-semibold ms-1"} style={{fontSize:'.73em'}}>({formatEUR(v.total_budget)})</small></span>
                        <Icon.Close onClick={()=>handleUnselect('ZONES',i)} />
                      </SpanBorderBadge>))
                    }
                  </div>
                  {
                    userType === 'rro' && (
                      <>
                      <hr />
                      <div className="mb-3 row">
                        <label htmlFor="#" className="col-sm-4 py-0 col-form-label text-secondary fw-semibold text-uppercase">
                          Asignar RO
                        </label>
                        <div className="col-sm-8 d-flex align-items-end">
                          <select className="form-select py-0" defaultValue={'default'} ref={refSelectRO} onChange={()=>{
                            let position = parseInt(refSelectRO.current.value)
                            let arr = [...selectedRO, listRO[position]]
                            setSelectedRO(arr)
                            let new_arr = listRO
                            new_arr.splice(position, 1)
                            setListRO(new_arr)
                            refSelectRO.current.value = "default"
                          }}>
                            <option disabled value="default">
                              ELEGIR RO
                            </option>
                            {
                              listRO.map((v,i)=>(
                                <option key={"{listRO}"+i} value={i}>{v.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      </>
                    )
                  }
                  <div className="mb-3 d-flex flex-wrap">
                    {
                      selectedRO.map((v,i)=>(
                      <span className='d-flex align-items-center border rounded-2 px-1 my-1 mx-1' key={"SRO-"+i}>
                        <SpanBadge className='my-0'>{v.name}</SpanBadge>
                        <Icon.Close onClick={()=>handleUnselect('RO', i)} />
                      </span>))
                    }
                  </div>
                  {
                    userType === 'ro' && (
                      <>
                      <hr />
                      <div className="mb-3 row">
                        <label htmlFor="#" className="col-sm-6 col-form-label fw-semibold text-secondary">
                          ESTACIÓN TRANSITORIA
                        </label>
                        <div className="col-sm-6 d-flex align-items-end">
                          <select className="form-select py-0" defaultValue={'default'} ref={refSelectTempStation} onChange={()=>{
                            let position = parseInt(refSelectTempStation.current.value)
                            let arr = [...selectedTempStationsList, tempStationsList[position]]
                            selectTempStation(arr)
                            let new_arr = tempStationsList;
                            new_arr.splice(position, 1)
                            setListStations(new_arr)
                            refSelectTempStation.current.value = "default"
                          }}>
                            <option disabled value="default">
                              ELEGIR ESTACIÓN
                            </option>
                            {
                              tempStationsList.map((v,i)=>(
                                <option key={"{listStations}"+i} value={i}>{v.name}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="mb-5 d-flex flex-wrap mt-3">
                        {
                          selectedTempStationsList.map((v,i)=>{
                            return(
                              <SpanBorderBadge key={"{tempStations}"+i} style={{minWidth: 'calc(33% - .403em)'}}>
                                {v.name}
                                <Icon.Close onClick={()=>handleUnselect('STATIONS', i)} />
                              </SpanBorderBadge>
                            )
                          })
                        }
                        </div>
                      </div>
                        <hr />
                        <div className='row'>
                          <div className='text-center col-sm-6'>
                            <h6 className='text-secondary fw-semibold mb-0' >ASIGNACIÓN POR ESTACIÓN</h6>
                            <h5 className='fw-semibold fs-3 mb-4'>{formatEUR((selectedZONES[0]?.budget || 0))}</h5>
                            <h5 className='text-secondary mb-0 fw-semibold'>TOTAL ZONA</h5>
                            <h1 className='fw-bold mb-0'>{formatEUR((selectedZONES[0]?.total_budget || 0))}</h1>
                          </div>
                        </div>
                      </>
                    )
                  }
                </>
              ) : null}

              <button
                type="submit"
                className="btn btn-primary float-end py-0 px-5 mt-3 fw-semibold rounded-5 text-uppercase"
              >
                {userSelected?'Actualizar':'Enviar'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  function handleSubmit(event) {
    const data = new FormData(event.currentTarget);
    const name = data?.get('name')?.toString() + '';
    const email = data?.get('email')?.toString() + '';
    const pass = data?.get('pass')?.toString() + '';
    event.preventDefault();
    onChange(name, email, pass, selectedZONES, selectedTempStationsList, selectedRO)
    setTimeout(()=> {
      setFields({})
      refCloseModal.current.click()}, 200)
  }
  function handleUnselect(type, index){
    let arr = type === 'RO'?[...selectedRO]:type === 'STATIONS'?[...selectedTempStationsList]:[...selectedZONES]
    let new_arr = type === 'RO'?[...listRO, selectedRO[index]]:type === 'STATIONS'?[...tempStationsList, selectedTempStationsList[index]]:[...ZONES, selectedZONES[index]]
    arr.splice(index,1)
    type === 'RO'?setListRO(new_arr):type === 'STATIONS'?setListStations(new_arr):setZONES(new_arr)
    type === 'RO'?setSelectedRO(arr):type === 'STATIONS'?selectTempStation(arr):setSelectedZONES(arr)
  }
};


const RowModal = ({ children }) => <div className="row">{children}</div>;
const LabelRow = ({ children, ...rest }) => (
  <label {...rest} className="col-sm-3 col-form-label pe-2 py-xs-0 text-sm-end text-uppercase" style={{fontSize:'.86em'}}>
    {children}:
  </label>
);

const InputRow = ({ children, ...rest }) => (
  <div className="col-sm-9 ps-sm-0 py-1">
    <input type="text" className="form-control py-0" {...rest} />
    {children}
  </div>
);

export default Section1Page22Modal;