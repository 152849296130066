import { useRef } from "react";

const ModalNotification = ({id, title, description, btnSend, onClickSend}) => {
    const refCloseModal = useRef()
    return (
        <div
            className="modal fade"
            id={id || "modalNotification"}
            tabIndex="-1"
            aria-labelledby="modalNotificationLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-1">
                        <h5 className="modal-title text-uppercase" id="modalNotificationLabel">
                            <span className='fw-semibold fs-6 text-secondary' >{''}</span> <span className='fw-semibold'>{title}</span>
                        </h5>
                        <button
                            type="button"
                            className="btn-close mb-0"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={refCloseModal}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <p className="fw-normal">
                                {description}
                            </p>
                            <button
                                type="submit"
                                className="btn btn-primary float-end py-0 px-4 mt-3 fw-semibold rounded-5 text-uppercase"
                                onClick={(e)=>{e.preventDefault();onClickSend();refCloseModal.current.click()}}
                            >
                                {btnSend||'Continuar'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalNotification;