import styled from "styled-components";

export const StyledContainer = styled.div`
  display: grid;
  grid-gap: 1rem;

  .border{
    border-radius: .9rem;
    padding: .5rem 1rem;
    height: 100%;
    width: 100%;
    display: block;
  }
  @media (min-width: 1600px){
    grid-template-columns: 1fr .35fr .55fr !important;
    grid-template-rows: 1fr !important;
    grid-template-areas: 
    "s1 s2 s3" !important;
  }
  @media (min-width: 1200px){
    grid-template-columns: 1fr .35fr;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 
    "s1 s2"
    "s3 x";
  }

  @media (min-width: 992px) and (max-width: 1200px){
    grid-template-columns: .55fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 
    "s1 s1"
    "s2 s3";
  }

  @media (max-width: 991px){
    grid-template-columns: .55fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 
    "s1 s1"
    "s2 s3";
  }
`

/**
 * COLUMNS
 * -------------
 */
export const StyledCol = styled.section`
  // background-color: #00ffff33; // Color marcador
  display: ${p=> (p.display || 'flex')};
  flex-direction: ${p=> (p.direction || 'column')};
  gap:1rem;
`

// COL 1 * FORM
export const StyledFormContainer = styled.span`
`
// FIRST_PART
export const StyledFirstPart = styled.span`
  position: relative;
  &:before{
    position: absolute;
    content: "";
    z-index: -1;
    background-color: #FBF8F6;
    height: 100%;
    width: calc(100% + 3rem);
    left:-2rem;
    top: 0;
  }
`

export const StyledBorderArticle = styled.article`
  background-color: #F1E8DD;
  border-radius: .7rem;
  position: relative;
  overflow: hidden;
  // @media (max-width: 992px){
  //   margin-top: 1rem;
  //   // width: 100% !important;
  // }
`

/**
 * SECTION 2 
 * ----------------------------------------------
 */
export const StyledHistory = styled.article`
`

export const StyledRowInput = styled.div`
  border-bottom: 1px solid #878787;
  &>label{
    text-transform: uppercase;
    font-weight: 600;
    font-size: .73rem;
    padding-left: .4rem;
  }
`

export const StyledContainerValue = styled.span`
  border-color: white;
  background-color: white;
`

export const StyledUploadImage = styled.span`
  background-image: url('/img/icons/camera-circle.svg');
  display: block;
  height: 2rem;
  width: 2rem;
  background-size: contain;
  background-repeat: no-repeat;
  cursor:pointer;
  position: absolute;
  right: .5rem;
  top : calc(50% - 1rem);
`

export const StyledSection2 = styled.section`
  position: relative;
  
  .tltFloat{
    position: absolute;
    margin-top: .2rem;
    margin-left: .4rem;
    opacity: .3;
    font-weight: 600;
  }

  textarea{
    border-radius: .3rem;
    margin: .5rem 0;
    min-height: 3rem;
    padding-top: 1.2rem;
    padding-left: .4rem;
    line-height: .8rem;
    border-color: #B2B2B2;
  }
`

export const StyledArticle3 = styled.article`
  background-color: #EDEDED;
  border-radius: .5rem;
  padding-bottom: .8rem;

  h3{
    padding-bottom: .5rem;
  }

  label{
    line-height: 1em;
  }

  input{
    border: none;
    border-radius: .2rem;
  }
`
export const StyledArticle4 = styled.article`
  border-radius: .5rem;
  border: 1px solid #878787;
`

export const StyledSection5 = styled.section`
  position: relative;
  border-radius: .5rem;
  background-color: #E5EFDC;
`

export const SpanSignature = styled.span`
  border-radius: .5em;
  background-color: white;
  position: absolute;
  display: block; 
  width: 40%;
  height: 4.5rem;
  right: 5%;
  top: calc(50% - 40%);
`

export const StyledSection6 = styled.section`
  border: 1px solid #FF8300;
  border-radius: .6rem;
  padding: .8rem 1rem;

  h3{
    padding-bottom: .9rem
  }

  .container-input{
    input { border: none; }
  }
`

export const StyledSection7 = styled.section`
  border-radius: .6rem;
  padding-bottom: .8rem;
  padding-left:  1rem;

  h3{
    padding-bottom: .9rem
  }
  input { border: none; margin: .25rem; border-radius: .4rem }
`

export const HistoryList = styled.ul``
export const HistoryItem = styled.li`
  position : relative;
  list-style: none;
  // padding-top: 1rem;
  font-size: .8em;
  color: #575756;
  line-height: 1.1em;
  strong{font-weight: 400}

  span{
    display: block;
  }

  &::marker{
    font-size: 1.5rem;
    color: #706F6F;
  }

  &:not(:last-child)::before{
    content: "";
    position: absolute;
    height: calc(100% - .6rem);
    left: .35em;
    top: calc(.7em + 1em);
    width: 1px;
    background-color: #706F6F;
  }

  &::after{
    content: "";
    position: absolute;
    top: calc(0px + 1em);
    left: 0;
    background-color: white;
    border-radius: 100%;
    border: 1.5px solid #706F6F;
    width:.6rem;
    height:.6rem;
  }
`

/**
 * SECTION 3 
 * ----------------------------------------------
 */
export const StyledArticleNotifications = styled.article`
  border-width: 1px;
  border-style: solid;
  border-color: #706F6F;
  width: 100%;
  height: fit-content;
  min-height: 10rem;
  padding: .5rem 1rem;
  border-radius: .9rem;
`

export const StyledArticleRequestPhotos = styled.article`
  border-width: 1px;
  border-style: solid;
  border-color: #706F6F;
  width: 100%;
  height: fit-content;
  min-height: 8rem;
  padding: .5rem 1rem;
  border-radius: .9rem;
`
export const StyledLineNotification = styled.div`
  & span.body{
    display: block;
    line-height: 1.1em;
    font-size: .82em;
  }
  &:not(:last-child){
    border-bottom: 1px solid #878787;
  }
`

export const PNStyledTitle = styled.strong`
  span{
    color: #575756;
  }
  color: ${p=> (p.color || '#575756')};
`