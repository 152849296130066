import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function RequireAuth({ children }) {
  const auth = useAuth();
  const { signInAccount } = auth;
  let location = useLocation();


  const handleSubmit = () => {
    signInAccount()
  }

  if (!auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}