import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledMenuBar = styled.div`
    * {text-transform: uppercase;}
    height: 100%;
    overflow: hidden;
`
export const StyledTitle = styled.h3`
    border-bottom: .56rem solid #77508D;
    padding-left: 20px;
    color: #575756;
    font-size: 1.1rem;
    font-weight: 700;
    padding-bottom: .6rem;
    margin-bottom: 1.3rem;
    display: flex;
    align-items: center;

    &:before{
        content: "";
        mask-image: url(${p=>p.icon});
        mask-repeat: no-repeat;
        background-color: ${p=>p.color};
        width: ${p=>(p.size||'1.4rem')};
        height: ${p=>(p.size||'1.4rem')};
        margin-right: 1rem;
    }
`

export const StyledMenuList = styled.ul`
    padding-bottom: 2.7em;

    & button{
        font-weight: 600;
        width: 100%;
        max-width: 12.18rem;
        margin-bottom: 2rem;
    }
`

export const StyledLink = styled(Link)`
    margin-bottom: 1rem;
`

export const StyledMenuItem = styled.li`
    margin: 0;
	padding: .1rem 0 .3rem 1rem;
	list-style: none;
	background-image: url(/img/icons/arrow-right.svg);
	background-repeat: no-repeat;
	background-position: 0 .6rem;
	background-size: 7px;

    & a{
        color: #000000;
        text-decoration: none;
        font-weight: 600;
    }
`