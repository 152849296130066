// ..
import { StyledMainTemplate, ChildrenContainer, Body } from './main.styles';
import { Header, MenuBar, Footer } from '../../modules/common';

const MainTemplate = ({ children }) => (
  <StyledMainTemplate>
    <Header>
      <Header.Logo />
      <Header.User />
      <Header.Summary className="d-lg-block" style={{paddingLeft:'10%'}} />
      <Header.Clock className="d-lg-block" />
      <Header.Role className="d-lg-block" />
    </Header>

    <Body className='pe-lg-5 pe-xl-6'>
      <MenuBar />
      <ChildrenContainer>
        {children}
      </ChildrenContainer>
    </Body>

    <Footer>
      <Footer.Copy />
      <Footer.Version />
    </Footer>
  </StyledMainTemplate>
);

export default MainTemplate;
