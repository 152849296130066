import styled from "styled-components"
export const OkCancelGroup = () => {
  return (<StyledWrapper><span><StyledCheck /><strong>FIRMAR</strong></span><span><StyledCancel /><strong>DENEGAR</strong></span></StyledWrapper>)
}

const StyledWrapper = styled.span`
  width: 100%;
  display: block;
  float: right;
  text-align: right;
  padding-right: 1em;
  position: relative;
  z-index: 2;
  &>span{
    cursor: pointer;
    display: inline-block;
    min-width: 8em;
    &:not(:first-child){
      margin-top: 1rem;
    }
  }
`

const StyledCheck = styled.span`
  mask-image : url('/img/icons/check-rounded.svg');
  background-color: #3EA536;
  width: 1.5rem;
  height: 1.5em;
  mask-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
`

const StyledCancel = styled.span`
  mask-image : url('/img/icons/cancel-rounded.svg');
  background-color: #CD1719;
  width: 1.5rem;
  height: 1.5em;
  mask-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
`