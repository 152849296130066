export const StatusValues = () => {
    return {
        CRITICO : "#CD171A",
        URGENTE : "#FF8300",
        MEDIO : "#FCD926",
        LEVE : "#027961"
    }
}

export const API = "https://mantenimiento-backend.desarrolloplenoil.com"
// export const API = "https://api.plenoil.grubbe.io"
// export const API = "http://plenoil.local"
export const PARAMS = {
    mode: "no-cors",
    cache: "no-cache",
    credentials: "same-origin",
}
export const HEADERS = {
    'Content-Type': 'application/json',
    'Accept': "application/json"}