import SolidHeading from '../components/solid-heading'
import MainTemplate from '../templates/main/main.template'
import NewRequest from '../modules/page1-1'

const CreateRequest = () => (
<MainTemplate>
  <SolidHeading title="Nueva solicitud de mantenimiento"><SolidHeading.End>Mod.26</SolidHeading.End></SolidHeading>
  <NewRequest />
  </MainTemplate>
)
export default CreateRequest