import MainTemplate from "../templates/main/main.template";
// ..SECTIONS
import { TableZones } from "../modules/page2-1";

const UsersPage = () => {
    return (
        <MainTemplate>
            <TableZones />
        </MainTemplate>
    )
}

export default UsersPage;