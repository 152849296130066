import React from 'react'
import { SolidHeading } from '../components'
import OutlineHeading from '../components/outline-heading'
import MainTemplate from '../templates/main/main.template'
const Table = React.lazy(() => import('../components/requests-table'))

const RequestsPage = () => (
  <MainTemplate>

    <OutlineHeading title="Mantenimiento" />

    <SolidHeading title="Solicitudes">
      <SolidHeading.InputContainer label={"ZONA"} options={['Guadalajara']} />
      <SolidHeading.InputContainer label={"ESTACIÓN"} options={['Miranda del Ebro II']} />
      <SolidHeading.InputContainer label={"MES"} options={['Enero 2023']} />
      <SolidHeading.InputContainer label="URGENCIA" options={['TODAS']} />
    </SolidHeading>

    <React.Suspense>
      <Table />
    </React.Suspense>

  </MainTemplate>
)
export default RequestsPage