import { useEffect, useState } from 'react';
import {
  StyledArticleNotifications,
  StyledArticleRequestPhotos,
  StyledLineNotification,
  PNStyledTitle
} from '../styles';


/**
 * START NOTIFICATIONS
 * @param {*} param0
 * @returns
 */
export const ArticleNotifications = ({ children }) => {
  return <StyledArticleNotifications>{children}</StyledArticleNotifications>;
};
export const PanelNotifications = ({ children, ...rest }) => {
  const ARR = [
    {
      title: 'NUEVA SOLICITUD POR FIRMAR',
      icon: null,
      color: "#FF8F43",
      subtitle: ' - 20 / 06 / 2022 - 17:02',
      body: null
    },
    {
      title: 'ATRASADO',
      icon: null,
      color: "#D74F38",
      subtitle: ' - 20 / 06 / 2022 - 17:02',
      body: null
    },
    {
    title: null,
    icon: null,
    color: null,
    subtitle: '20 / 06 / 2022 - 17:02',
    body: "Nobisqui vene num ius sim volor aut a pliti occum qui aspelectet id qui arumqua tustiistor"
    },
    {
    title: null,
    icon: null,
    color: null,
    subtitle: '20 / 06 / 2022 - 17:02',
    body: "Nobisqui vene num ius sim volor aut a pliti occum qui aspelectet id qui arumqua tustiistor"
    },
    {
    title: null,
    icon: null,
    color: null,
    subtitle: '20 / 06 / 2022 - 17:02',
    body: "Nobisqui vene num ius sim volor aut a pliti occum qui aspelectet id qui arumqua tustiistor"
    }
  ];
  return (
    <div style={{height:'14.7rem', overflowY:'scroll'}} className={"mt-4 mb-5 me-22 ps-3 pe-4"} {...rest}>
      {ARR.map((v, i) => (
        <StyledLineNotification key={'value-' + i} style={{paddingTop: '.3rem', paddingBottom: '.55rem',marginLeft: '1rem'}}>
          <PNStyledTitle style={{ fontSize: '.78em' }} color={v.color}>
            {v.title}
            <span>{v.subtitle}</span>
          </PNStyledTitle>
          <br />
          <span className="body">{v.body}</span>
        </StyledLineNotification>
      ))}
    </div>
  );
};
/**
 * END NOTIFICATIONS
 */

export const ArticlePhotos = ({ children }) => {
  return <StyledArticleRequestPhotos>{children}</StyledArticleRequestPhotos>;
};

export const DivPhotos = ({ ARR_IMGS, imgSelected, onSelect }) => {
  return (
    <div>
      {ARR_IMGS.map((v, i) => (
        <div
          data-bs-toggle="modal"
          onClick={(e) => {
            onSelect(v);
            e.preventDefault()
          }}
          data-bs-target="#photoModal"
          key={'.sd-' + i}
          style={{
            display: 'inline-block',
            margin: '.2rem',
            borderRadius: '.5rem',
            overflow: 'hidden',
            border: '1px solid lightgray',
            width: '4rem',
            height: '4rem'
          }}
        >
          <img src={v} alt="Imagen adjunta" width="60" />
        </div>
      ))}
    </div>
  );
};
