import { StyledSection2 } from '../styles'
import { ArticleBordered } from '../../../components';
// ..HOOKS
import { useTools } from '../../../hooks/useTools';

export const SECTION2 = ({...rest}) => {
  const {formatEUR} = useTools()
  return (
    <StyledSection2 style={{flex:0}} {...rest}>
      <ArticleBordered>
        <ArticleBordered.Head title={'RESUMEN MES'} />
        <Tlt>ENERO</Tlt>
        <Subtl>PRESUPUESTO: {formatEUR(20000)}</Subtl>
        <Line>
          <span>PRESUPUESTO CONSUMIDO: </span>
          <strong>{formatEUR(15000)} | 85%</strong>
        </Line>
        <Line>
          <span>No DE SOLICITUDES: </span>
          <strong>45</strong>
        </Line>
        <Line>
          <span>CON GARANTÍA: </span>
          <strong>11 | {formatEUR(8000)}</strong>
        </Line>
        <Line>
          <span>CON SEGURO: </span>
          <strong>14 | {formatEUR(3000)}</strong>
        </Line>
      </ArticleBordered>
      <ArticleBordered>
        <ArticleBordered.Head title={'RESUMEN ANUAL'} />
        <Tlt>ENERO</Tlt>
        <Subtl>TOTAL: {formatEUR(40000)}</Subtl>
        <Line>
          <span>Nº DE SOLICITUDES: </span>
          <strong>265</strong>
        </Line>
        <Line>
          <span>CON GARANTÍA: </span>
          <strong>46 | {formatEUR(8000)}</strong>
        </Line>
        <Line>
          <span>CON SEGURO: </span>
          <strong>56 | {formatEUR(3000)}</strong>
        </Line>
      </ArticleBordered>
    </StyledSection2>
  );
};

const Tlt = ({children}) => <h3 style={{fontSize: '.9em', fontWeight: '600'}}>{children}</h3>
const Subtl = ({children}) => <h2 style={{fontSize: '1.3em', fontWeight: '600'}}>{children}</h2>
const Line = ({children}) => <div style={{fontSize: '.8em'}} >{children}</div>