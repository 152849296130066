import { BurgerLine, ClockContainer, Container, HamburgerMenu, Label, LogoContainer, LogoutLink, RoleContainer, SpanClock, SpanRole, SpanUser, SpanValue, SpanWelcome, SpanZone, SummaryContainer, TltSummary, UserContainer, Value } from './header.styles';
// ..hoooks
import { useTools } from '../../../hooks/useTools'
import { useEffect, useState } from 'react';
import {MenuBar} from '../'
import useAuth from '../../../hooks/useAuth';

export default function Header({ children, ...rest }) {

  const [show, setShow] = useState(false)

  return <>

<div className={`offcanvas offcanvas-end ${show && 'show'}`} tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div className="offcanvas-header">
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" onClick={()=>setShow(!show)} aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
    <MenuBar />
  </div>
</div>
<Container {...rest}>{children}
<HamburgerMenu className="btn btn-primary" data-bs-toggle="offcanvas" onClick={()=>{setShow(true)}} role="button" aria-controls="offcanvasExample">
  <BurgerLine />
  <BurgerLine />
  <BurgerLine />
</HamburgerMenu>
</Container>
  </>;
}

Header.Logo = function HeaderLogo({ ...rest }) {
  return (
    <LogoContainer {...rest}>
      <img src="/img/plenoil-logo.svg" alt="Logo de Plenoil" />
    </LogoContainer>
  );
};

Header.User = function HeaderUser({ ...rest }) {
  const {logout, user} = useAuth()
  return (<UserContainer {...rest}>
    <SpanWelcome>bienvenido/a</SpanWelcome> 
    <SpanUser><span>{(user?.name||'usuario plenoil invitado')}</span></SpanUser>
    <LogoutLink onClick={logout}>desconectar</LogoutLink>
    </UserContainer>);
};

Header.Summary = function HeaderSummary({ ...rest }) {
  const { formatEUR } = useTools();
  return <SummaryContainer {...rest}>
    <TltSummary>resumen total</TltSummary>
    <SpanValue>
      <Value className='text-primary'>{formatEUR(23000)}</Value>
      <Label className='text-primary'>Resumen estaciones</Label>
    </SpanValue>
  </SummaryContainer>;
};

Header.Clock = function HeaderClock({ ...rest }) {
  const date = new Date()
  const hour = date.getHours()
  const min = date.getMinutes()
  const [sec, setSec] = useState(0)

  useEffect(()=>{
    setInterval(() => {
      setSec(new Date().getSeconds().toLocaleString())
    }, 1000)
  },[])

  return (<ClockContainer {...rest}>
    <SpanClock>{hour < 10 ? "0"+hour:hour}:{min < 10 ? "0"+min:min}:{sec < 10 ? "0"+sec:sec}</SpanClock>
    </ClockContainer>);
};

Header.Role = function HeaderRole({ ...rest }) {
  const { user } = useAuth()

  return (<RoleContainer {...rest}>
    <SpanRole>{user?.role || 'guest'}</SpanRole>
    <SpanZone>{user?.zone?.name || '--'}</SpanZone>
  </RoleContainer>);
};
