import styled from "styled-components";

export const StyledContainerPage17 = styled.main`  
  // ...
`

export const StyledSection2 = styled.section`
  display: flex;
  flex-direction: row;
  gap:1rem;
  width: 100%;
  margin-top: 1.5rem;
  align-items: flex-start;
  height: fit-content;
  &>article{
    width: 17rem;
  }
  flex:0;
  flex-wrap:wrap;
  @media (min-width: 1750px){
    flex:1;
    flex-wrap:nowrap;
  }
`