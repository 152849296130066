import MainTemplate from '../templates/main/main.template';
import { StyledContainerPage17 } from '../modules/page1-7/styles';

// ..SECTIONS
import { Header, SECTION1, SECTION2 } from '../modules/page1-7'

const OverviewPage = () => (
  <MainTemplate>
   <Header />
    <StyledContainerPage17 className='d-block d-xxl-flex px-sm-2 px-lg-0 gap-1'>
      <SECTION1 />
      <SECTION2 className="justify-content-xxl-end" />
    </StyledContainerPage17>
  </MainTemplate>
);

export default OverviewPage;