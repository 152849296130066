import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
// ..Pages
import CreateRequest from './pages/page1-1';
import RequestsPage from './pages/page1-2';
import OverviewPage from './pages/page1-7';
// --
import ZonesPage from './pages/page2-1';
import UsersPage from './pages/page2-2';
import RequireAuth from './auth/require-auth'
import LoginPage from './pages/login';
import useSession from './hooks/useSession';

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" >
      <Route path="" element={<RequireAuth><CreateRequest /></RequireAuth>} />
      <Route path="login" element={<LoginPage />} />
      <Route path='users' element={
        <RequireAuth>
          <UsersPage />
        </RequireAuth>
      }
        loader={({ request }) => fetch("/api/database.json", { signal: request.signal })}
      />
      <Route path='zones' element={<RequireAuth><ZonesPage /></RequireAuth>}
        loader={({ request }) => fetch("/api/database.json", { signal: request.signal })}
      />
      <Route path='*' element={<h1>404</h1>} />
      <Route path='solicitudes' element={<RequireAuth><RequestsPage /></RequireAuth>} loader={({ request }) =>
        fetch("/api/database.json", {
          signal: request.signal,
        })
      } />
      <Route path={"nueva-solicitud" || ""} element={<RequireAuth><CreateRequest /></RequireAuth>} />
      <Route path="resumen" element={<RequireAuth><OverviewPage /></RequireAuth>} loader={({ request }) =>
        fetch("/api/database.json", {
          signal: request.signal,
        })} />
    </Route>
  )
);

const App = () => {
  const { HandleSessionStorage } = useSession();
  HandleSessionStorage();
  return <RouterProvider router={Router} />
};

export default App;
