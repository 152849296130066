import { create } from 'zustand';

export const tableExtension = create(set => ({
    wTable:0,
    wSticky: 0,
    wContent:0,
    scrollPosition: 0,
    heightTable : 0,
    scrollDown : false,
    xPosition : 0,
    p0: 0,
    setWTable: val => set(() => ({ wTable: parseInt(val) })),
    setWSticky: val => set(() => ({ wSticky: parseInt(val) })),
    setWContent: val => set(() => ({ wContent: parseInt(val) })),
    setScrollPosition: val => set(() => ({ scrollPosition: parseInt(val) })),
    pressScrollDown : (val) => set(()=>({scrollDown: val })),
    setHeightTable: val => set(()=>({heightTable : parseInt(val)})),
    setXposition : val => set(()=>({xPosition : val})),
    setP0: val => set(()=>({p0 : val}))
  }));

const useTable = () => {
    const {pressScrollDown, setXposition} = tableExtension()

    function scrollBarDown(e){
        console.log("press", true)
        pressScrollDown(true)
        setXposition(e.clientX)
    }

    const scrollBarUp = () =>{
        console.log("press", false)
        pressScrollDown(false)
    }

    function scrollPosition(e){
        const {wSticky} = tableExtension()
        console.log(wSticky)
    }

    return {
        scrollBarDown,
        scrollPosition,
        scrollBarUp
    }
}
export default useTable;