import MainTemplate from "../templates/main/main.template";
// ..SECTIONS
import { TableUsers } from "../modules/page2-2";

const UsersPage = () => {
    return (
    <MainTemplate>
        <TableUsers />
    </MainTemplate>
    )
}



export default UsersPage;