import React, {useState} from 'react';
import { useLoaderData } from 'react-router-dom';
import styled from 'styled-components';
import { useTools } from '../../../hooks/useTools';
import { PrintSection, Select } from '../../../components';

const TableOverviewModule = ({ ...rest }) => {
  const DATA = useLoaderData();
  const { OVERVIEW_DATA } = DATA;
  const { formatEUR } = useTools();
  const [itemSelected, selectItem] = useState()
  return (
    <span className='d-xxl-flex flex-fill'>
    <section {...rest}
      style={{ overflowX: 'scroll', overflowY:'hidden' }}
      className={"w-100"}
      id="PRINT_OVERVIEW"
    >
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" style={{width:'70%'}}>ESTACIÓN</th>
              <th scope="col" className="text-center">
                Nº SOLICITUDES
              </th>
              <th scope="col" className="text-center">
                TOTAL
              </th>
              <th scope="col" className="text-center">
                GARANTÍA
              </th>
              <th scope="col" className="text-center">
                SEGURO
              </th>
              <th scope="col" className="text-center">
                TOTAL AÑO
              </th>
            </tr>
          </thead>

          <StyledTableBody>
            {
              OVERVIEW_DATA.map((v, i) => (
                <React.Fragment key={'DATA-' + i}>
                  <tr style={{cursor: 'pointer'}} onClick={() => {
                    itemSelected !== v ? selectItem(v) : selectItem(undefined)
                  }}>
                    <th scope="row">{v.name}</th>
                    <td style={{ textAlign: 'center' }}>
                      <strong>{v.request_num}</strong>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <strong>{formatEUR(v.total)}</strong>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <strong>{formatEUR(v.waranty)}</strong>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <strong>{formatEUR(v.insurance)}</strong>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <strong>{formatEUR(v.total_year)}</strong>
                    </td>
                  </tr>
                  {/* {
                    v === itemSelected && (
                    )
                  } */}
                    <span style={{}}>
                      <td colSpan="1" style={{padding:0}}>
                        <div style={{ transition:'height .3s', height:v === itemSelected ?'362px':0, overflow: 'hidden'}}>{TableMonthList()}</div>
                      </td>
                      <td colSpan="5" style={{padding:0,marginTop:-1, position: 'relative'}}>
                        <div className={"px-2"} style={{transition:'.3s',position:'absolute', top:'20%', overflow: 'hidden', opacity: v === itemSelected?1:0}}>
                          <h3 className='pt-3 fs-6 fw-semibold ps-2'>ENERO 2023</h3>
                          <table class="table table-borderless" style={{fontSize: '.87em'}}>
                            <tbody>
                              <tr>
                                <td className={"py-1 border-end-0"} style={{color:'black'}}><strong>01</strong></td>
                                <td className={"py-1 border-end-0"}>Nº Pedido: <strong>1254879854</strong></td>
                                <td className={"py-1 border-end-0"}>Día: <strong>12/01</strong></td>
                                <td className={"py-1 border-end-0"}>Coste: <strong>{formatEUR(450)}</strong></td>
                              </tr>
                              <tr>
                                <td className={"py-1 border-end-0"} style={{color:'black'}}><strong>01</strong></td>
                                <td className={"py-1 border-end-0"}>Nº Pedido: <strong>1254879854</strong></td>
                                <td className={"py-1 border-end-0"}>Día: <strong>12/01</strong></td>
                                <td className={"py-1 border-end-0"}>Coste: <strong>{formatEUR(450)}</strong></td>
                              </tr>
                              <tr>
                                <td className={"py-1 border-end-0"} style={{color:'black'}}><strong>01</strong></td>
                                <td className={"py-1 border-end-0"}>Nº Pedido: <strong>1254879854</strong></td>
                                <td className={"py-1 border-end-0"}>Día: <strong>12/01</strong></td>
                                <td className={"py-1 border-end-0"}>Coste: <strong>{formatEUR(450)}</strong></td>
                              </tr>
                              <tr>
                                <td className={"py-1 border-end-0"} style={{color:'black'}}><strong>01</strong></td>
                                <td className={"py-1 border-end-0"}>Nº Pedido: <strong>1254879854</strong></td>
                                <td className={"py-1 border-end-0"}>Día: <strong>12/01</strong></td>
                                <td className={"py-1 border-end-0"}>Coste: <strong>{formatEUR(450)}</strong></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </span>
                </React.Fragment>
              ))}
          </StyledTableBody>
        </table>
    </section>
      <PrintSection className="mt-5 mx-3" id="PRINT_OVERVIEW">IMPRIMIR</PrintSection>
    </span>
  );
};

const StyledTableBody = styled.tbody`
  box-shadow: 0 0 0.5rem #c8c8c8;
`;

const TableMonthList = () => {

  const ARR_MONTHS=[
    {month: "ENERO", requests: 2, total: 450},
    {month: "FEBRERO", requests: 2, total: 450},
    {month: "MARZO", requests: 2, total: 450},
    {month: "ABRIL", requests: 0, total: 0},
    {month: "JUNIO", requests: 2, total: 450},
    {month: "JULIO", requests: 2, total: 450},
    {month: "AGOSTO", requests: 2, total: 450},
    {month: "SEPTIEMBRE", requests: 2, total: 450},
    {month: "NOVIEMBRE", requests: 2, total: 450},
    {month: "DICIEMBRE", requests: 2, total: 450},
  ]
  const {formatEUR} = useTools()

  return (<>
    <div className='d-flex align-items-center my-3 mx-3 mx-xxl-5'>
      <span style={{fontSize: '.75em'}} className={"fw-semibold col-7 text-end pe-3"}>AÑO</span>
      <Select options={["2023"]} className="border rounded"></Select>
    </div>
    <div className='px-3 px-xxl-5'>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" className='border-end-0'></th>
            <th scope="col" className='text-center border-end-0'>SOLICITUDES</th>
            <th scope="col" className='text-center border-end-0'>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {
            ARR_MONTHS.map((v, i)=>(
              <tr key={"{s"+i}>
                <th scope="row" className='border-end-0 py-0'>{v.month}</th>
                <td className='text-center border-end-0 py-0 fw-semibold'>{v.requests}</td>
                <td className='text-center border-end-0 py-0 fw-semibold'>{formatEUR(v.total)}</td>
              </tr>
            ))
          }
        </tbody>
        
      </table>
    </div>
  </>)
}

export default TableOverviewModule;
