import styled from "styled-components";

export const StyledArticleBordered = styled.article`
  border-width: 1px;
  border-style: solid;
  border-color: #706F6F;
  width: 100%;
  height: fit-content;
  min-height: 10rem;
  // padding: .5rem 1rem;
  border-radius: .9rem;
`

export const StyledHeadContainer = styled.span`
display: flex;
position: relative;
margin-bottom: 1rem;
justify-content: space-between;
align-items:center;
&:after{
  left: 0;
  content: "";
  // margin-left: -1rem;
  // width: calc(100% + 2rem);
  width: 100%;
  bottom: -.5rem;
  position: absolute;
  height: .25rem;
  background-image: url('/img/icons/line-group.svg');
  background-repeat: repeat-x;
  background-size: contain;
  background-color: #F1F1F1;
}
button{
  border-radius: 10rem;
  font-size: .8em;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 600;
}
`
// TODO: Tiene una réplica (DEJAR ESTE)
export const StyledTitleContainer = styled.span`
  color: #878787;
  font-weight: 600;
`