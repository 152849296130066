import styled from "styled-components";
import { SolidHeading } from "../../components";
import OutlineHeading from "../../components/outline-heading";
import { useTools } from "../../hooks/useTools";

const Header = () => {
  const {formatEUR} = useTools()
  return (
    <>
      <OutlineHeading title="Resumen" />
      <SolidHeading>
        <div className="d-flex">
          <SolidHeading.InputContainer label={"ZONA"} options={['Guadalajara']} />
          <SolidHeading.InputContainer label={"ESTACIÓN"} options={['Miranda del Ebro II']} />
          <SolidHeading.InputContainer label={"MES"} options={['Enero 2023']} />
        </div>
        <SolidHeading.End>
          <ListValues className={"fw-normal"}>
            <span className="pe-2">SALDO TOTAL {formatEUR(20000)}</span>
            <span className="pe-1 ps-2">DISPONIBLE {formatEUR(10000)}</span>
            <span className="pe-1 ps-2">85% Usado</span>
          </ListValues>
        </SolidHeading.End>
      </SolidHeading>
    </>
  );
};

const ListValues = ({children, ...rest}) => <StyledListValues {...rest}>{children}</StyledListValues>
const StyledListValues = styled.span`
  font-size: .8em;
  &>span:not(:last-child){
    border-right: 1px solid white;
  }
`
export default Header