import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledSubheaderUsers = styled.div`
    border-bottom: 1px solid #878787;
`
    
    export const StyledLink = styled(Link)`
    overflow:hidden;

    display:inline-block;
    border-left: 1px solid #878787;
    padding: .1em 1em;
    vertical-align: middle;
    text-transform : uppercase;
    color: #878787;
    font-weight: 600;
    border-bottom:4px solid transparent;
    cursor: pointer;
    text-decoration: none;
    &:hover , &.active{
        color: black;
        border-bottom:4px solid var(--bs-primary);
    }
`

export const StyledContainerTable = styled.div`
    font-size: .8em;
    width: 100%;
    max-width:50em;
`

export const SpanCheckboxContainer = styled.span`
    input{
        margin-right: 1em;
    }
    span{
        cursor: pointer;
        &:hover{
            color: var(--bs-primary);
        }
    }
`