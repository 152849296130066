import {
  StyledContainer,
  StyledCol,
  StyledFormContainer,
} from './styles';
// ..STORE
import { useStorePhotos } from './store'
// ..COMPONENTS
import { ArticleBordered, PrintSection, SpanBadge } from '../../components'
// ..SECTIONS
import { FIRST_PART, SECOND_PART } from './sections/first-section';
import HistorySection from './sections/second-section';
import { DivPhotos, PanelNotifications } from './sections/third-section'
import { useState } from 'react';

const NewRequestTemplate = () => {
  const { ARR_IMG1, ARR_IMG2 } = useStorePhotos()
  const [imgSelected, selectImg] = useState();

  return (
    <StyledContainer>
      <StyledCol id="FORM" direction="row" className='formContainer' style={{ gridArea: 's1' }}>
        <StyledFormContainer
          className="border"
          id="PRINT_FORM"
          style={{ fontSize: '.8em' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }} >
              <strong style={{ whiteSpace: 'nowrap', marginRight: '.5rem' }} >CREADO POR: </strong>
              <SpanBadge>Juan López Méndez</SpanBadge>
            </div>
            <strong>02/06/2023</strong>
          </div>

          <FIRST_PART />
          <SECOND_PART />

        </StyledFormContainer>
        <PrintSection id="PRINT_FORM">IMPRIMIR</PrintSection>
      </StyledCol>

      <StyledCol style={{ gridArea: 's2' }}>
        <ArticleBordered style={{ height: '100%' }}>
          <ArticleBordered.Head title={"HISTORIAL"}>
            <PrintSection id="HISTORY_SECTION" />
          </ArticleBordered.Head>
          <HistorySection id="HISTORY_SECTION" />
        </ArticleBordered>
      </StyledCol>

      <StyledCol style={{ gridArea: 's3' }}>
        <ArticleBordered className={"pb-2"}>
          <ArticleBordered.Head title={"ALERTAS"}>
            <button type="button" className='btn btn-primary'>NUEVA ALERTA</button>
          </ArticleBordered.Head>
          <PanelNotifications />
        </ArticleBordered>

        <ArticleBordered>
          <ArticleBordered.Head title={"FOTOS SOLICITUD"} />
          <DivPhotos ARR_IMGS={ARR_IMG1} onSelect={e => selectImg(e)} />
        </ArticleBordered>

        <ArticleBordered>
          <ArticleBordered.Head title={"FOTOS CONTROL CALIDAD"} />
          <DivPhotos ARR_IMGS={ARR_IMG2} onSelect={e => selectImg(e)} />
        </ArticleBordered>
      </StyledCol>
      <div
        className="modal fade"
        id="photoModal"
        tabIndex="-1"
        aria-labelledby="photoModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <img src={imgSelected} alt="Previsualización" />
        </div>
      </div>
    </StyledContainer>
  );
};

export default NewRequestTemplate;
