import { Container, SpanCopy, SpanVersion } from "./footer.styles"
const date = new Date();
const year = date.getFullYear()
const shortYear =year.toString().replace("20", "")

export default function Footer({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>
}

Footer.Copy = function FooterCopy({ ...rest }) {
  return <SpanCopy>PLENOIL {year}</SpanCopy>
}

Footer.Version = function FooterVersion({ ...rest }) {
  return <SpanVersion>V01/{shortYear}</SpanVersion>
}