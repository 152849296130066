import { Link } from 'react-router-dom'
import { MenuBar } from '../../components'
import { Header } from '../../modules/common'

const MenuBarModule = () => {
    return (
      <MenuBar>
        {/* <Header.Summary className="d-block" />
        <Header.Clock className="d-block" />
        <Header.Role className="d-block" />
        <hr /> */}
        <MenuBar.Title icon="/img/icons/maintenance.svg" color="#9B9E91">
          Mantenimiento
        </MenuBar.Title>
        <MenuBar.List>
          <Link to="/nueva-solicitud"><button className='btn btn-primary rounded-pill'>Nueva solicitud</button></Link>
          <MenuBar.Item><Link to="/solicitudes">Solicitudes</Link></MenuBar.Item>
          <MenuBar.Item><Link style={{opacity:.1}}>Aprobadas</Link></MenuBar.Item>
          <MenuBar.Item><Link style={{opacity:.1}}>Planificadas</Link></MenuBar.Item>
          <MenuBar.Item><Link style={{opacity:.1}}>Ejecutadas</Link></MenuBar.Item>
          <MenuBar.Item><Link style={{opacity:.1}}>Desestimadas</Link></MenuBar.Item>
          <MenuBar.Item><Link to="/resumen">Resumen estaciones</Link></MenuBar.Item>
        </MenuBar.List>
        
        <MenuBar.Title icon="/img/icons/maintenance.svg" color="#E6AA41">
          Usuarios
        </MenuBar.Title>
        <MenuBar.List>
          <MenuBar.Item><Link to="/zones">Zonas</Link></MenuBar.Item>
          <MenuBar.Item><Link to="/users">Usuarios</Link></MenuBar.Item>
        </MenuBar.List>
      </MenuBar>
    )
}

export default MenuBarModule