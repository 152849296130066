import { HistoryList, HistoryItem } from '../styles'

const ARR = [
    ["20 / 06 / 2022", "Registro de petición", "RO: Nombre Apellidos"],
    ["20 / 06 / 2022", "Registro de petición", "RO: Nombre Apellidos"],
    ["20 / 06 / 2022", "Registro de petición", "RO: Nombre Apellidos"],
    ["20 / 06 / 2022", "Registro de petición", "RO: Nombre Apellidos"],
    ["20 / 06 / 2022", "Registro de petición", "RO: Nombre Apellidos"],
    ["20 / 06 / 2022", "Registro de petición", "RO: Nombre Apellidos"],
]

const HistorySection = ({ ...rest }) => {
    return <section {...rest}>
        <HistoryList className=''>
            {
                ARR.map((v, i) => (
                    <HistoryItem key={"hs-"+i} className="ps-3 py-22">
                        <strong>{v[0]} </strong>
                        <span>{v[1]}</span>
                        <span>{v[2]}</span>
                    </HistoryItem>
                ))
            }
        </HistoryList>
    </section>
}

export default HistorySection;