import { StyledHeading, SpanTitle, SpanNavigation, StyledSpan, StyledInputContainer,StyledEndHead } from './solid-heading.styles'

const SolidHeading = ({ title, children, ...rest }) => {
  return (
    <StyledHeading {...rest} className={"px-3 py-0 mb-4"}>
      {title && (<SpanTitle>{title}</SpanTitle>)}
      <SpanNavigation style={{width:(!title ? '100%' : 'none')}}>
        {children}
      </SpanNavigation>
    </StyledHeading>
  );
};

SolidHeading.End = function SolidHeadingEnd({children}){
  return <StyledEndHead>{children}</StyledEndHead>
}

SolidHeading.Span = function SolidHeadingSpan({ children, ...rest }) {
  return <StyledSpan {...rest} className={"my-2"}>{children}</StyledSpan>
}

SolidHeading.InputContainer = function SolidHeadingInputContainer({ children, label, options, ...rest }) {
  return <div style={{display:'flex',flexDirection:'row', justifyContent: 'center', alignItems: 'center'}}>
    <SolidHeading.Span className='text-white'>{label}</SolidHeading.Span>
    <StyledInputContainer {...rest}>
      <select name="zone" id="zone">
        {options.map((v, i) => (
          <option key={v + "-" + i} value={v}>{v}</option>
        ))}
      </select>{children}
    </StyledInputContainer>
  </div>
}

export default SolidHeading;
