import { API, HEADERS } from "../constants";
import useAuth from "../hooks/useAuth";

const useFetch = () => {
    const { token } = useAuth();

    async function listUsers(role) {
        const response = await fetch(`${API}/api/v1/users/role/${(role||'ADMIN')}`, {
            method: 'GET',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token}
        })
        return {status: response.status , json: response.json()};
    }

    async function getUser(id) {
        const response = await fetch(`${API}/api/v1/users/${(id)}`, {
            method: 'GET',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token}
        })
        return {status: response.status , json: response.json()}
    }

    async function createUser(object) {
        const response = await fetch(`${API}/api/v1/users/`, {
            method: 'POST',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token},
            body: JSON.stringify(object)
        })
        return {status: response.status , json: response.json()};
    }

    async function updateUser(id,object) {
        const response = await fetch(`${API}/api/v1/users/${id}`, {
            method: 'PUT',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token},
            body: JSON.stringify(object)
        })
        return {status: response.status , json: response.json()};
    }

    async function deleteUser(id) {
        const response = await fetch(`${API}/api/v1/users/${id}`, {
            method: 'DELETE',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token}
        })
        return {status: response.status , json: response.json()};
    }

    async function listZones() {
        const response = await fetch(`${API}/api/v1/zones`, {
            method: 'GET',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token}
        })
        return {status: response.status , json: response.json()};
    }

    async function updateZone(id,object) {
        const response = await fetch(`${API}/api/v1/zones/${id}`, {
            method: 'PUT',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token},
            body: JSON.stringify(object)
        })
        return {status: response.status , json: response.json()};
    }

    async function getZone(id) {
        const response = await fetch(`${API}/api/v1/zones/${id}`, {
            method: 'GET',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token}
        })
        return response.json();
    }

    async function deleteZone(id) {
        const response = await fetch(`${API}/api/v1/zones/${id}`, {
            method: 'DELETE',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token}
        })
        return {status: response.status , json: response.json()}
    }

    async function createZone(name, budget, total_budget, stations) {
        const response = await fetch(`${API}/api/v1/zones`, {
            method: 'POST',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token},
            body: JSON.stringify({
                "name": name,
                "budget": budget,
                "total_budget": total_budget,
                "stations": stations,
            })
        })
        return {status: response.status , json: response.json()}
    }

    async function listStations() {
        const response = await fetch(`${API}/api/v1/stations`, {
            method: 'GET',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token}
        })
        return {status: response.status , json: response.json()};
    }

    async function getStationsByZone(idZone) {
        const response = await fetch(`${API}/api/v1/zones/${idZone}/stations`, {
            method: 'GET',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token}
        })
        return response.json()
    }

    async function removeStations(idZone, stations) {
        const response = await fetch(`${API}/api/v1/zones/${idZone}/stations`, {
            method: 'PUT',
            headers: {...HEADERS, 'Authorization': 'Bearer '+token},
            body: JSON.stringify({"stations":[...stations]})
        })
        return {status: response.status , json: response.json()}
    }

    function USER(idUSER){

        const storedADMIN = window.sessionStorage.getItem('admin')
        const storedDN = window.sessionStorage.getItem('dn')
        const storedRRO = window.sessionStorage.getItem('rro')
        const storedRO = window.sessionStorage.getItem('ro')
        const storedRM = window.sessionStorage.getItem('rm')
        const storedPRL = window.sessionStorage.getItem('prl')

        const lAdmin = (prop) => {
            const objADMIN = JSON.parse(storedADMIN) || []
            return new Promise ((resolve, reject)=>{
                if(!storedADMIN || prop === 'refresh') listUsers("admin").then(({ status, json }) => { if (status === 200) json.then(res => window.sessionStorage.setItem('admin', JSON.stringify(res.data || []))) })
                resolve(objADMIN);
            })
        }

        const lDN = (prop) => {
            const objDN = JSON.parse(storedDN) || []
            return new Promise ((resolve, reject)=>{
                if(!storedDN || prop === 'refresh') listUsers("dn").then(({ status, json }) => { if (status === 200) json.then(res => window.sessionStorage.setItem('dn', JSON.stringify(res.data || []))) })
                resolve(objDN);
            })
        }

        const lRRO = (prop) => {
            const objRRO = JSON.parse(storedRRO) || []
            return new Promise ((resolve, reject)=>{
                if(!storedRRO || prop === 'refresh') listUsers("rro").then(({ status, json }) => { if (status === 200) json.then(res => window.sessionStorage.setItem('rro', JSON.stringify(res.data || []))) })
                resolve(objRRO);
            })
        }
        
        const lRO = (prop) => {
            const objRO = JSON.parse(storedRO) || []
            return new Promise ((resolve, reject)=>{
                if(!storedRO || prop === 'refresh') listUsers("ro").then(({ status, json }) => { if (status === 200) json.then(res => window.sessionStorage.setItem('ro', JSON.stringify(res.data || []))) })
                resolve(objRO);
            })
        }

        const lRM = (prop) => {
            const objRM = JSON.parse(storedRM) || []
            return new Promise ((resolve, reject)=>{
                if(!storedRM || prop === 'refresh') listUsers("rm").then(({ status, json }) => { if (status === 200) json.then(res => window.sessionStorage.setItem('rm', JSON.stringify(res.data || []))) })
                resolve(objRM);
            })
        }

        const lPRL = (prop) => {
            const objPRL = JSON.parse(storedPRL) || []
            return new Promise ((resolve, reject)=>{
                if(!storedPRL || prop === 'refresh') listUsers("prl").then(({ status, json }) => { if (status === 200) json.then(res => window.sessionStorage.setItem('prl', JSON.stringify(res.data || []))) })
                resolve(objPRL);
            })
        }

        const awaitCreate = (type, object) => {
            const storedTYPE = window.sessionStorage.getItem(type);
            let objTYPE = JSON.parse(storedTYPE) || []
            return new Promise((resolve, reject)=>{
                createUser(object).then(({status, json})=>{
                    json.then(res=>{
                        if(status === 200 || status === 201){
                            objTYPE.push(res.data)
                            window.sessionStorage.setItem(type, JSON.stringify(objTYPE))
                            resolve(objTYPE)
                        }else{
                            alert(res.message)
                        }
                    })
                })
            })
        }

        const awaitInfo = () => {
            return new Promise((resolve, reject)=>{
                getUser(idUSER).then(({ json, status }) => {
                    json.then(res => {
                        if (status === 200) {
                            resolve(res)
                        }
                    })
                })
            })
        }

        const awaitUpdate = (object) => {
            return new Promise((resolve,reject)=>{
                updateUser(idUSER, object).then(({json, status})=>{
                    if (status === 200) {
                        json.then(updatedUser=> {
                            if(object.role === 'admin') lAdmin('refresh').then(res=> {window.sessionStorage.setItem(object.role, JSON.stringify(res));resolve(updatedUser)})
                            if(object.role === 'dn') lDN('refresh').then(res=> {window.sessionStorage.setItem(object.role, JSON.stringify(res));resolve(updatedUser)})
                            if(object.role === 'rro') lRRO('refresh').then(res=> {window.sessionStorage.setItem(object.role, JSON.stringify(res));resolve(updatedUser)})
                            if(object.role === 'ro') lRO('refresh').then(res=> {window.sessionStorage.setItem(object.role, JSON.stringify(res));resolve(updatedUser)})
                            if(object.role === 'rm') lRM('refresh').then(res=> {window.sessionStorage.setItem(object.role, JSON.stringify(res));resolve(updatedUser)})
                            if(object.role === 'prl') lPRL('refresh').then(res=> {window.sessionStorage.setItem(object.role, JSON.stringify(res));resolve(updatedUser)})
                        })
                    }
                }
                )
            })
        }

        const awaitDelete = (type, arr) => {
            return new Promise((resolve, reject)=>{
                let i = 0;
                asyncDEL(arr).then(()=>{
                    console.log("tipo::",type)
                    if(type === 'admin') lAdmin('refresh').then(res=> {window.sessionStorage.setItem(type, JSON.stringify(res));resolve('success')})
                    if(type === 'dn') lDN('refresh').then(res=> {window.sessionStorage.setItem(type, JSON.stringify(res));resolve('success')})
                    if(type === 'rro') lRRO('refresh').then(res=> {window.sessionStorage.setItem(type, JSON.stringify(res));resolve('success')})
                    if(type === 'ro') lRO('refresh').then(res=> {window.sessionStorage.setItem(type, JSON.stringify(res));resolve('success')})
                    if(type === 'rm') lRM('refresh').then(res=> {window.sessionStorage.setItem(type, JSON.stringify(res));resolve('success')})
                    if(type === 'prl') lPRL('refresh').then(res=> {window.sessionStorage.setItem(type, JSON.stringify(res));resolve('success')})
                })
            })
        }

        async function asyncDEL(arr){
          await arr.forEach(element => {
            deleteUser(element.id)
          })
        }

        return{
            lAdmin,
            lDN,
            lRRO,
            lRO,
            lRM,
            lPRL,
            awaitCreate,
            awaitInfo,
            awaitUpdate,
            awaitDelete,
        }
    }

    function ZONE(idZONE){
        const storedZONE = window.sessionStorage.getItem(idZONE);
        const objZONE = JSON.parse(storedZONE) || []
        // ..
        const storedZONES = window.sessionStorage.getItem('zones');
        const objZONES = JSON.parse(storedZONES) || []

        const lZones = (prop) => {
            const objZONES = JSON.parse(storedZONES) || []
            return new Promise((resolve, reject)=>{
                if(!storedZONES || prop === 'refresh') {
                    listZones().then(({ status, json }) => { 
                        if (status === 200) json.then(res => { window.sessionStorage.setItem('zones', JSON.stringify(res.data || []));resolve(res.data);console.log(res.data) });})
                    }
                else{
                    resolve(objZONES);
                }
            })
        }

        const awaitCreate = (name,budget,total_budget, stations) => {
            return new Promise((resolve, reject)=>{
                createZone(name, parseFloat(budget), parseFloat(total_budget), stations).then(({ status, json }) => {
                    if (status === 200 || status === 201){
                        json.then(res=>{
                            objZONES.push(res.data)
                            window.sessionStorage.setItem('zones',JSON.stringify(objZONES))
                            resolve(objZONES)
                        })
                    }else{
                        resolve(objZONES)
                    }
                })
            })
        }

        const awaitInfo = () => {
            return new Promise((resolve, reject)=>{
                if(!objZONE?.budget){
                    getZone(idZONE).then(res=>{
                        let object = {}
                        object = JSON.stringify({...objZONE, ...res.data} || {budget:0.2});
                        window.sessionStorage.setItem(idZONE, object);
                        resolve(object)
                    })
                }else{
                    resolve(JSON.stringify(objZONE))
                }
            })
        }

        const awaitStations = () =>{
            return new Promise((resolve, reject) => {
                if(!objZONE.stations){
                    getStationsByZone(idZONE).then(res => {
                        let object = JSON.stringify({...objZONE, stations: res.data} || [])
                        window.sessionStorage.setItem(idZONE, object);
                        if (res.data){
                            resolve(res.data)
                        }
                    })
                }else{
                    resolve(objZONE.stations)
                }
            });
        }

        const awaitUpdate = (object) => {
            return new Promise((resolve,reject)=>{
                updateZone(idZONE, object).then(({json, status})=>{
                    if (status === 200) {
                        json.then(res=> {
                            window.sessionStorage.setItem(idZONE, JSON.stringify(res.data)); 
                            lZones('refresh').then(()=> resolve(res.data))});
                    }
                })
            })
        }

        const awaitDelete = () => {
            return new Promise((resolve, reject)=>{
                deleteZone(idZONE).then(({ status, json }) => {
                    if (status === 200 || status === 201){
                        let element = objZONES.find(item=>item.id === idZONE);
                        element = objZONES.indexOf(element)
                        objZONES.splice(element,1)
                        window.sessionStorage.setItem('zones', JSON.stringify(objZONES));
                        window.sessionStorage.removeItem(idZONE)
                        resolve(objZONES)
                    }else{
                        resolve(objZONES)
                    }
                })
            })
        }

        return {
            lZones,
            awaitCreate,
            awaitInfo,
            awaitStations,
            awaitUpdate,
            awaitDelete
        }
    }

    return {
        USER,
        createUser,
        listUsers,
        getUser,
        updateUser,
        deleteUser,
        listZones,
        listStations,
        createZone,
        getStationsByZone,
        removeStations,
        ZONE,
        getZone,
        updateZone,
        deleteZone,
    }
}

export default useFetch;