import styled from "styled-components"

const SmallButton = ({icon,children, ...rest}) => {
    return(
        <Container {...rest}><StyledIcon icon={icon} /><WrapperChildren>{children}</WrapperChildren></Container>
    )
}


const Container = styled.div`
  cursor: pointer;
  text-align: center;
  display: inline-block;
  `
  const StyledIcon = styled.span`
  display: block;
  width: 2rem;
  height: 1.1em;
  // background-image : url('/img/icons/printer.svg');
  color:white;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 50%;
  background-color: #77508D;
  border-radius: 10rem;
  margin: auto;
  `
  
  const WrapperChildren = styled.span`
  height: 100%;
  display: block; 
  font-weight: 600; 
  font-size: .6em; 
  margin-top: .15rem;
`

export default SmallButton;