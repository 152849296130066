
import { useEffect, useRef, useState } from 'react';
import { Icon, SpanBorderBadge } from '../../../components'
import { useTools } from '../../../hooks/useTools';
import useFetch from '../../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';


function Section1Page22Modal({ zoneData, stations }) {
  const refCloseModal = useRef();
  const refSelectStation = useRef();
  const navigate = useNavigate()
  const { formatEUR } = useTools()

  // const [STATIONS, setSTATIONS] = useState([{ name: "Madrid IV", id: "01" }, { name: "Madrid II", id: "02" }, { name: "Madrid III", id: "03" }, { name: "Fuenlabrada VI", id: "04" }, { name: "Fuenlabrada V", id: "05" }, { name: "Fuenlabrada IV", id: "06" }, { name: "El Escorial", id: "07" }, { name: "Madrid I", id: "08" }])
  const { createZone, updateZone, ZONE } = useFetch()
  const [STATIONS, setSTATIONS] = useState([])
  const [selectedSTATIONS, setSelectedSTATIONS] = useState([])
  const [inputValue, setInputValue] = useState(0);
  const [useData, setData] = useState({})
  const [useFields, setFields] = useState({})

  // Notification type
  const modalTrigger = document.querySelectorAll('[data-modal-type]');
  const createTrigger = modalTrigger.item(0)
  const editTrigger = modalTrigger.item(1)

  useEffect(() => {
    loadStations()
    createTrigger?.addEventListener('click', handleCreate)
    editTrigger?.addEventListener('click', handleEdit)
  }, [createTrigger, editTrigger, stations])

  const loadStations = () => {
    const storedSTATIONS = window.sessionStorage.getItem('stations');
    const objSTATIONS = JSON.parse(storedSTATIONS) || [];
    setSTATIONS(objSTATIONS)
  }

  const handleCreate = () => {
    setData({ title: 'Añadir', btnSend: 'Enviar', zone: undefined })
    setSelectedSTATIONS([])
    setFields({})
    setInputValue(0)
  }

  const handleEdit = (v) => {
    let {value} = v.currentTarget.attributes['data-modal-type']
    setData({ title: 'Editar', btnSend: 'Actualizar', zone: value })
    
    setFields(zoneData)
    setInputValue(zoneData.budget)

    let arr = stations || [];
    const newValues = STATIONS;
    let index = -1;
    setSelectedSTATIONS(arr)
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      const result = STATIONS.find(({id})=> id === element.id)
      index = STATIONS.indexOf(result);
      if(index > -1){
        newValues.splice(index,1)
      }
    }
    if(newValues.length > 0){
      setSTATIONS([...newValues])
    }

  }

  return (
    <div
      className="modal fade"
      id="modalZone"
      tabIndex="-1"
      aria-labelledby="modalZone"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header pb-1">
            <h5 className="modal-title text-uppercase" id="modalZone">
              <span className='fw-semibold fs-6 text-secondary'>{useData?.title || 'Añadir'}</span> <span className='fw-semibold'>{/*useData?.zone ||*/ 'ZONA'}</span>
            </h5>
            <button
              type="button"
              className="btn-close mb-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={refCloseModal}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <RowModal>
                <LabelRow htmlFor="name">Nombre</LabelRow>
                <InputRow id="name" name="name" required defaultValue={(useFields?.name || '')} />
              </RowModal>
              <hr />
              <RowModal>
                <label htmlFor="#" className="col-sm-5 fw-semibold text-secondary">
                  ESTACIONES ASOCIADAS
                </label>
                <div className="col-sm-7 d-flex align-items-end">
                  <select className="form-select py-1 fw-semibold" style={{ fontSize: '.8em' }} defaultValue={'default'} ref={refSelectStation} onChange={() => {
                    let position = parseInt(refSelectStation.current.value)
                    let arr = [...selectedSTATIONS, STATIONS[position]]
                    setSelectedSTATIONS(arr)
                    let new_arr = STATIONS
                    new_arr.splice(position, 1)
                    setSTATIONS(new_arr)
                    refSelectStation.current.value = "default"
                  }}>
                    <option disabled value="default">
                      ESTACIONES
                    </option>
                    {
                      STATIONS.map((v, i) => (
                        <option key={v.id} value={i}>{v.name}</option>
                      ))
                    }
                  </select>
                </div>
              </RowModal>

              <div className="mb-5 d-flex flex-wrap mt-3">
                {
                  selectedSTATIONS.map((v, i) => (
                    <SpanBorderBadge key={"SSTATIONS-" + i} style={{ minWidth: 'calc(33% - .403em)' }}>
                      {v.name}
                      <Icon.Close className={"ms-3"} onClick={() => handleUnselect('STATIONS', i)} />
                    </SpanBorderBadge>))
                }
              </div>
              <hr />
              <RowModal>
                <label htmlFor="#" className="col-sm-6 py-1 fw-semibold text-secondary text-end">
                  ASIGNACIÓN POR ESTACIÓN
                </label>
                <div className='col-sm-6 ps-sm-0 py-1'>
                  <input type="number" className="form-control py-0 fs-5 fw-semibold" value={inputValue} name="budget"
                    onChange={(e) => { setInputValue(e.currentTarget.value) }}
                  />
                </div>
              </RowModal>
              <RowModal>
                <label htmlFor="#" className="col-sm-6 fw-semibold text-secondary text-end">
                  TOTAL ZONA
                </label>
                <div className='col-sm-6 ps-sm-3 fs-1 fw-semibold'>
                  <span>{formatEUR((inputValue || 0) * selectedSTATIONS.length)}</span>
                  <input type={"number"} name="total_budget" readOnly hidden value={((inputValue || 0) * selectedSTATIONS.length)} />
                </div>
              </RowModal>
              <button
                type="submit"
                className="btn btn-primary float-end py-0 px-5 mt-3 fw-semibold rounded-5 text-uppercase"
              >
                {(useData.btnSend || 'Enviar')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  function handleSubmit(event) {
    const data = new FormData(event.currentTarget);
    const name = data?.get('name')?.toString() + '';
    const budget = data?.get('budget')?.toString() + '';
    const total_budget = data?.get('total_budget')?.toString() + '';
    event.preventDefault();
    if (useData.zone) {
      ZONE(zoneData.id).awaitUpdate({name: name, budget: budget, total_budget: total_budget, stations: selectedSTATIONS})
      .then(()=> navigate(0))
    }
    else {
      // ..create zone from Session hook
      ZONE().awaitCreate(name, budget, total_budget, selectedSTATIONS)
      .then(res=> {navigate('/zones#'+res[res.length-1].id);navigate(0)})
    }
  }
  function handleUnselect(type, index) {
    let arr = type === 'STATIONS' ? [...selectedSTATIONS] : []
    let new_arr = type === 'STATIONS' ? [...STATIONS] : []
    const findElement = STATIONS.find(({id})=> id === selectedSTATIONS[index].id)
    const position = STATIONS.indexOf(findElement)
    if(position === -1) new_arr = [selectedSTATIONS[index],...STATIONS]
    arr.splice(index, 1)
    type === 'STATIONS' && setSTATIONS(new_arr)
    type === 'STATIONS' && setSelectedSTATIONS(arr)
  }
};


const RowModal = ({ children }) => <div className="row align-items-center">{children}</div>;
const LabelRow = ({ children, ...rest }) => (
  <label {...rest} className="col-sm-3 col-form-label pe-2 py-xs-0 text-sm-end text-uppercase" style={{ fontSize: '.86em' }}>
    {children}:
  </label>
);

const InputRow = ({ children, ...rest }) => (
  <div className="col-sm-9 ps-sm-0 py-1">
    <input type="text" className="form-control py-0" {...rest} />
    {children}
  </div>
);

export default Section1Page22Modal;