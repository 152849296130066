import React, { useState } from "react";
import styled from "styled-components";

export default function Select({ options, onValue, ...rest }) {
  const [valSelect, setVal] = useState()
  const handleChange = (e) => {
    setVal(e.currentTarget.value)
    return e.currentTarget.value
  }
  return <StyledInputContainer style={{width:'100%', paddingLeft:'.2rem'}} {...rest}>
    <select onChange={(e)=> onValue && onValue(handleChange(e))} value={valSelect} style={{width:'100%',  fontWeight: '400', fontSize: '.9rem', textOverflow: 'ellipsis', paddingRight:'1rem'}}>
      {(valSelect && <option>{valSelect}</option>)}
      {options.map((v,i)=>(
        valSelect !== v &&<option key={v+"-"+i} name={v}>{v}</option>
      ))}
    </select>
  </StyledInputContainer>
}

export const StyledInputContainer = styled.div`
  font-size: .85rem;
  position: relative;
  & select, & input{
    cursor: pointer;
    background-color: transparent;
    font-weight: 600;
    appearance: none;
    border: none;
    outline: none;
  }

  &:before{
    content: "";
    mask-image : url("/img/icons/arrow-down.svg");
    transform: rotate(135deg);
    background-color: #575756;
    mask-repeat: no-repeat;
    width: .55rem;
    height: .55rem;
    position: absolute;
    right: .66rem;
    bottom: .6em;
    pointer-events: none;
  }

`