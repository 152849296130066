import styled from "styled-components";

export const StyledIcon = styled.span`
  background-color: #CD171A;
  height: .87em;
  width: .87em;
  display: inline-block;
  cursor: pointer;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-image: url(${p=>p.src});
`