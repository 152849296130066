import styled from "styled-components";

export const SpanBadge = styled.span`
  width: 100%;
  color: black;
  display: flex; // No modificar
  align-items: center;
  margin-top: -.2rem;
  font-size: .85rem;
  font-weight: 500;

  &:before{
    content: "RO";
    color: white;
    background-color: #FFD1C2;
    font-size: .6rem;
    font-weight: 700;
    border-radius: .25rem;
    padding: 0 .2rem;
    margin-right: .5rem;
  }
`