import {StyledIcon} from './styles';

function Icon ({src, ...rest}) {
  return <StyledIcon className={"ms-2"} src={src} {...rest}></StyledIcon>
}

Icon.Close = function IconClose({icon, ...rest}){
  return <Icon src={'/img/icons/error-rounded.svg'} {...rest}/>
}


export default Icon;