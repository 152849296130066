// FIXME: Cambiar document.ID por ___useRef___
import { useEffect, useState } from 'react';
import {
  Content,
  InnerContent,
  InnerSticky,
  MaskContent,
  Row,
  RowHeading,
  RowContent,
  Sticky,
  StyledTable,
  StyledFooter,
  IconWarning,
  IconCheck,
  IconPencil,
  IconClock,
  IconProgress,
  StyledScrollBar,
  ScrollIndicator,
  BorderContent
} from './base-table.styles';
import { useRef } from 'react';
import useTable from '../../hooks/useTable';
import { tableExtension } from '../../hooks/useTable';

export default function Table({ children, ...rest }) {
  const { scrollBarUp } = useTable();
  const { heightTable, scrollDown, p0, wContent, wTable, xPosition } = tableExtension();
  return (
    <StyledTable
      id="table"
      {...rest}
      style={{ height: heightTable }}
      onMouseMove={(e) => {
        if (scrollDown) {
          const dy = e.clientX - xPosition
          document.getElementById("content").scrollLeft += dy / 16
        }
      }}
      onMouseUp={scrollBarUp}
      onMouseLeave={scrollBarUp}
    >
      {children}
      {/* <Table.ScrollBar /> */}
    </StyledTable>)
}

Table.Sticky = function TableSticky({ children, ...rest }) {
  const { scrollPosition, setWSticky, setWContent, setWTable } = tableExtension();
  useEffect(() => {
    setWTable(document.getElementById('table')?.getBoundingClientRect().width.toFixed(0));
    setWSticky(document.getElementById('sticky')?.getBoundingClientRect().width);
    setWContent(document.getElementById('content')?.getBoundingClientRect().width.toFixed(0))
    window.addEventListener(
      'resize',
      () => {
        setWTable(document.getElementById('table')?.getBoundingClientRect().width.toFixed(0));
        setWSticky(document.getElementById('sticky')?.getBoundingClientRect().width)
        setWContent(document.getElementById('content')?.getBoundingClientRect().width.toFixed(0))
      },
    );
  }, [setWSticky]);
  return (
    <Sticky
      {...rest}
      id="sticky"
      className={`${scrollPosition > 0 && 'stickyShadow'}`}
    >
      <InnerSticky>{children}</InnerSticky>
    </Sticky>
  );
};

Table.StickyRow = function TableStickyRow({ children, ...rest }) {
  return <Row {...rest}>{children}</Row>;
};

Table.Content = function TableContent({ children, ...rest }) {
  const { setScrollPosition, setHeightTable, wSticky } = tableExtension();
  const refContent = useRef()
  useEffect(() => {
    setHeightTable(refContent.current.getBoundingClientRect().height)
  }, [refContent, setHeightTable])

  return (
    <Content
      id="content"
      wSticky={wSticky}
      onScroll={e => {
        setScrollPosition(e.currentTarget.scrollLeft);
      }}
      ref={refContent}
      {...rest}
    >
      <BorderContent />
      {children}
      <MaskContent />
    </Content>
  );
};

Table.ContentRow = function TableContentRow({ children, ...rest }) {
  return <InnerContent {...rest}>{children}</InnerContent>;
};

Table.HeadingCell = function TableContentRow({ children, ...rest }) {
  return <RowHeading {...rest}>{children}</RowHeading>;
};

Table.ContentCell = function TableContentRow({ children, block, ...rest }) {
  return <RowContent {...rest} style={{display: block?"block":"auto"}}>{children}</RowContent>;
};

Table.ScrollBar = function TableScrollBar({ children, ...rest }) {
  const { scrollBarDown, scrollBarUp } = useTable();
  const { wTable, wSticky, p0, setP0 } = tableExtension();
  const sl = document.getElementById("content")?.scrollLeft
  const sw = document.getElementById("content")?.scrollWidth
  /**
   * sw(scroll-width): (px) Ancho total del contenido
   * sl(scroll-left) : (px) Desplazamiento entre el p0 del contenedor y p0 del scroll
   * visor: (px) Espacio de visión. Muestra lo que está dentro de este marco
   * p0 : (%) Posición incial del scroll
   * wHidden : (px) Ancho oculto entre el contenido y el visor
   * wHidden_PER : (%) Porcentaje de wHidden
   * pmax : (px) Máximo valor que puede alcanzar el scroll en x (ancho del scroll)
  */
  const visor = wTable - wSticky
  // const p0 = (sl*100)/sw
  const wHidden = sw - visor
  const wHidden_PER = (wHidden * 100) / sw
  const [pmax, setpmax] = useState()
  // Escucha los cambios de medidas
  useEffect(() => { setP0((sl * 100) / sw); setpmax(100 - wHidden_PER) }, [wTable, wSticky, sw, sl, wHidden])

  return <StyledScrollBar {...rest} style={{ bottom: (pmax < 100 ? '0px' : '-100vh') }}><ScrollIndicator onMouseDown={scrollBarDown} onMouseUp={scrollBarUp} style={{ left: p0 + '%', width: pmax + '%' }} /></StyledScrollBar>
}

Table.Footer = function TableFooter({ children, ...rest }) {
  return (
    <StyledFooter {...rest}>
      <strong>LEYENDA</strong>
      <span>
        <IconWarning />
        Alerta incidencia
      </span>
      <span>
        <IconCheck />
        Pedido finalizado</span>
      <span>
        <IconPencil />
        Pedido a firmar</span>
      <span>
        <IconClock />
        Pedido programado</span>
      <span>
        <IconProgress />
        En ejecución</span>
    </StyledFooter>)
}
